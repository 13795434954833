import { LDL } from "@cur8/health-risks-calc";
import { PathLink } from "@pomle/react-router-paths";
import { calcAge } from "lib/age";
import { calcScore2 } from "lib/scores/score2/calcScore2";
import { isScore2HighRisk } from "lib/scores/score2/score2";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { paths } from "render/routes/paths";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import {
  useBrachialPressureMetric,
  useLdlMetric,
  useNonHdlMetric,
  useSmokingMetric,
} from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricBloodworkLdl() {
  const { data: patient, isLoading: isPatientLoading } = usePatientQuery();
  const { data: ldl, isLoading: isLdlLoading } = useLdlMetric();
  const { data: nonHDL, isLoading: isNonHdlLoading } = useNonHdlMetric();
  const { data: brachialPressure, isLoading: isBrachialPressureLoading } =
    useBrachialPressureMetric();
  const { data: smoking, isLoading: isSmokingLoading } = useSmokingMetric();

  if (
    isPatientLoading ||
    isLdlLoading ||
    isNonHdlLoading ||
    isBrachialPressureLoading ||
    isSmokingLoading
  ) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!ldl || !nonHDL || !brachialPressure || !smoking || !patient) {
    return null;
  }

  const dateOfBirth = patient.dateOfBirth;
  const age = dateOfBirth ? calcAge(dateOfBirth, DateTime.now()) : NaN;

  const score2 = calcScore2({
    patient,
    date: DateTime.now(),
    nonHDL: nonHDL.at(0),
    brachial: {
      left: brachialPressure.left.at(0),
      right: brachialPressure.right.at(0),
    },
    smoking: smoking.at(0),
  });

  const isScore2ResultHighRisk = isScore2HighRisk(age, score2?.score);
  const { cur, prev } = getMetricHistory(ldl);
  const riskRanges = LDL.rangesFor({
    age,
    isScore2HighRisk: isScore2ResultHighRisk || false,
  });
  const metricRating = cur && riskRanges.findRisk(cur.unit);

  const value = cur?.unit["mmol/L"];
  const prevValue = prev?.unit["mmol/L"];

  return (
    <PathLink to={paths.ldlMetric.url({})}>
      <Metric
        label={<Trans.MetricName />}
        metricRating={metricRating}
        value={value}
        subLabel={<Trans.MetricUnit />}
        previousValue={prevValue}
      />
    </PathLink>
  );
}
