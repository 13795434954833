import { Risk } from "@cur8/health-risks-calc";
import { useMemo } from "react";
import { Values } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details/types";
import { Trans } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/trans";
import { BadgeState } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/types";

type useBadgeStateProps = Pick<Values, "value" | "metricRating">;

export function useBadgeState({
  value,
  metricRating,
}: useBadgeStateProps): BadgeState | undefined {
  const badgeState = useMemo(() => {
    const noData = {
      text: <Trans.NoData />,
      variant: "noData",
    } as const;

    const warning = {
      text: <Trans.Warning />,
      variant: "warning",
    } as const;

    const normal = {
      text: <Trans.Normal />,
      variant: "normal",
    } as const;

    const optimal = {
      text: <Trans.Optimal />,
      variant: "normal",
    } as const;

    const record: Record<Risk, BadgeState> = {
      [Risk.HighRisk]: warning,
      [Risk.ImmediateRisk]: warning,
      [Risk.Risk]: warning,
      [Risk.Normal]: normal,
      [Risk.Optimal]: optimal,
      [Risk.Unknown]: normal,
    };
    if (value === undefined) {
      return noData;
    }

    if (metricRating === undefined) {
      return undefined;
    }

    return record[metricRating];
  }, [value, metricRating]);

  return badgeState;
}
