import { PathLink } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useWeightMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricWeight() {
  const { data, isLoading: isDataLoading } = useWeightMetric();

  if (isDataLoading) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!data) {
    return null;
  }

  const { cur: curWeight, prev: prevWeight } = getMetricHistory(data);

  const value = curWeight && +curWeight.unit.kilograms.toFixed(2);
  const prev = prevWeight ? +prevWeight.unit.kilograms.toFixed(2) : undefined;

  return (
    <PathLink to={paths.weightMetric.url({})}>
      <Metric
        label={<Trans.MetricName />}
        value={value}
        metricRating={undefined}
        subLabel={<Trans.MetricUnit />}
        previousValue={prev}
      />
    </PathLink>
  );
}
