import { BookableSlot, fromAPI } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { querySlots } from "render/views/booking/MultiSiteMonthView/api/slot";
import styles from "./styles.module.sass";

interface DayProps {
  now: DateTime;
  date: DateTime;
  bookableUntil: DateTime;
  siteIds: string[];
  onClick: (date: DateTime) => void;
  selected?: boolean;
  isToday?: boolean;
}

export function Day({
  now,
  date,
  bookableUntil,
  siteIds,
  onClick,
  isToday,
  selected,
}: DayProps) {
  const api = useAPIClient();
  const [slots, setSlots] = useState<BookableSlot[]>();

  const isInThePast = date.startOf("day") < now.startOf("day");
  const isPastBookableDate = date.startOf("day") > bookableUntil.startOf("day");

  useEffect(() => {
    function fetchSlots() {
      const interval = {
        from: DateTime.max(DateTime.now(), date.startOf("day")),
        to: date.endOf("day"),
      };
      const request = querySlots(api, {
        siteIds,
        start: interval.from,
        end: interval.to,
        pageSize: 1,
      });

      return {
        result: request.result.then((res) =>
          res.items.map(fromAPI.toBookableSlot)
        ),
        abandon: request.abandon,
      };
    }

    if (selected) {
      const req = fetchSlots();
      req.result.then(setSlots).catch(silenceAbort);
      return () => {
        req.abandon();
      };
    }

    if (isInThePast || isPastBookableDate) {
      return;
    }

    const req = fetchSlots();
    req.result.then(setSlots).catch(silenceAbort);
    return () => {
      req.abandon();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, date, isInThePast, isPastBookableDate, siteIds.join(""), selected]);

  return (
    <button
      data-selected={selected}
      data-today={isToday}
      onClick={() => onClick(date)}
      className={styles.Day}
      disabled={!slots?.length || isInThePast || isPastBookableDate}
    >
      {date.day}
    </button>
  );
}
