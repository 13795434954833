import { Slot } from "@cur8/rich-entity";
import { useVisitPrerequisites } from "render/hooks/api/useVisitPrerequisites";
import { useTracking } from "render/hooks/useTracking";
import { Expandable } from "render/ui/presentation/Expandable";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { appointmentEvents } from "../../tracking";
import { Trans } from "../../trans";
import styles from "./styles.module.sass";

interface CompleteProfileButtonProps {
  slot: Slot;
}

export function CompleteProfileButton({ slot }: CompleteProfileButtonProps) {
  const { trackEvent } = useTracking();

  const { isReady, getNextStep } = useVisitPrerequisites({
    origin: "appointment",
  });

  return (
    <Expandable isOpen={isReady === false}>
      <ActionButton
        disabled={isReady}
        onClick={() => {
          trackEvent(appointmentEvents.completeProfileClick());
          getNextStep()?.(slot.slotId);
        }}
        variant="suggestion"
      >
        <div className={styles.profileCompleteButtonText}>
          <div>
            <Trans.CompleteProfileButton.Title />
          </div>
          <div className={styles.subText}>
            <Trans.CompleteProfileButton.Sub />
          </div>
        </div>
      </ActionButton>
    </Expandable>
  );
}
