import { EyePressureCombined } from "@cur8/health-risks-calc";
import { PathLink } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { pickHighestSide } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEyePressure/highestSide";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useEyePressureMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricEyePressure() {
  const { data, isLoading: isDataLoading } = useEyePressureMetric();

  if (isDataLoading) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!data) {
    return null;
  }

  const { cur: latestLeftEyePressure, prev: prevLeftEyePressure } =
    getMetricHistory(data.left);
  const { cur: latestRightEyePressure, prev: prevRightEyePressure } =
    getMetricHistory(data.right);

  const { eyePressure: highestEyePressure, side: highestSide } =
    pickHighestSide({
      left: latestLeftEyePressure,
      right: latestRightEyePressure,
    });
  const previousEyePressure =
    highestSide === "left" ? prevLeftEyePressure : prevRightEyePressure;

  const metricRating =
    latestLeftEyePressure !== undefined && latestRightEyePressure !== undefined
      ? EyePressureCombined.riskFor({
          left: latestLeftEyePressure?.unit,
          right: latestRightEyePressure?.unit,
        })
      : undefined;

  return (
    <PathLink to={paths.eyePressureMetric.url({})}>
      <Metric
        metricRating={metricRating}
        value={highestEyePressure?.unit.mmHg}
        label={<Trans.MetricName />}
        subLabel={<Trans.MetricLabel />}
        previousValue={previousEyePressure?.unit.mmHg}
      />
    </PathLink>
  );
}
