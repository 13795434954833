import { localize } from "render/context/InternationalizationContext";

const CheckList = {
  Eating: localize({
    en_GB: <>You can eat, but avoid large portions</>,
    sv_SE: <>Du kan äta, men undvik gärna stora portioner</>,
  }),
  Exercises: localize({
    en_GB: <>You can exercise normally</>,
    sv_SE: <>Du kan träna som vanligt</>,
  }),
  Caffeine: localize({
    en_GB: <>Avoid caffeine 2 hours in advance</>,
    sv_SE: <>Undvik koffein 2 timmar innan </>,
  }),
  Skin: localize({
    en_GB: <>For the skin scan, avoid wearing patterned or baggy underwear</>,
    sv_SE: (
      <>
        Undvik att bära mönstrade eller löst sittande underkläder för din hud
        scan
      </>
    ),
  }),
};

const BeforeYourAppointment = localize({
  en_GB: <>Before your appointment</>,
  sv_SE: <>Innan ditt besök</>,
});

export const Trans = { CheckList, BeforeYourAppointment };
