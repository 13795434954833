import { useHasAttendedVisitInSweden } from "render/hooks/api/queries/useVisitsQuery";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { useSkinLesionCountMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricSkinDetails() {
  const { data } = useSkinLesionCountMetric();
  const { data: hasAttendedVisitInSweden } = useHasAttendedVisitInSweden();

  const values = {
    subLabel: undefined,
    value: data,
    previousValue: undefined,
    metricRating: undefined,
    scanDate: undefined,
    ranges: undefined,
  };

  const isClinicalStudy = hasAttendedVisitInSweden === true;

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={1}
      setScanNum={() => {}}
      numOfScans={1}
      isClinicalStudy={isClinicalStudy}
      FAQText={Trans.FAQ}
    />
  );
}
