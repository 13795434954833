import { Answers } from "@cur8/questionnaire";
import { Patient, Sex } from "@cur8/rich-entity";
import { Country } from "lib/country";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Chapters } from "render/views/OnboardingView/components/OnboardingFlowView/components/Questions/components/Chapters";
import { StepView } from "./components/StepView";
import { Step, StepProps } from "./step";
import * as Cardiovascular from "./steps/family/cardiovascular";
import * as Diabetes from "./steps/family/diabetes";
import * as Heritage from "./steps/family/heritage";
import * as FamilyIntro from "./steps/family/intro";
import * as RelevantConditions from "./steps/family/relevantConditions";
import * as SkinCancer from "./steps/family/skinCancer";
import * as CardioExercise from "./steps/lifestyle/cardioExercise";
import * as Diet from "./steps/lifestyle/diet";
import * as Drinking from "./steps/lifestyle/drinking";
import * as LifeStyleIntro from "./steps/lifestyle/intro";
import * as Smoking from "./steps/lifestyle/smoking";
import * as Snus from "./steps/lifestyle/snus";
import * as StrengthExercise from "./steps/lifestyle/strengthExercise";
import * as Sunburn from "./steps/lifestyle/sunburn";
import * as AdditionalWorries from "./steps/medicalHistory/additionalWorries";
import * as Allergies from "./steps/medicalHistory/allergies";
import * as BloodPressure from "./steps/medicalHistory/bloodPressure";
import * as CardioConditions from "./steps/medicalHistory/cardioConditions";
import * as MedicalHistoryDiabetes from "./steps/medicalHistory/diabetes";
import * as HealthConditions from "./steps/medicalHistory/healthConditions";
import * as HealthProblems from "./steps/medicalHistory/healthProblems";
import * as MedicalHistoryIntro from "./steps/medicalHistory/intro";
import * as Medications from "./steps/medicalHistory/medications";
import * as OtherHealthConditions from "./steps/medicalHistory/otherHealthConditions";
import * as Pregnant from "./steps/medicalHistory/pregnant";
import * as SkinConditions from "./steps/medicalHistory/skinConditions";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

function EndStep({
  onComplete,
  currentQuestion,
  totalQuestions,
  onPrev,
  isSubmitting,
}: StepProps) {
  return (
    <StepView
      onPrev={onPrev}
      cta={
        <ActionButton variant="suggestion" onClick={onComplete}>
          {isSubmitting ? (
            <Trans.General.Saving />
          ) : (
            <Trans.General.CloseAndExit />
          )}
        </ActionButton>
      }
      totalQuestionCount={totalQuestions}
      currentQuestion={currentQuestion}
    >
      <section className={styles.content}>
        <Typography variant="title-large">
          <Trans.General.ThanksForYourAnswers />
        </Typography>
        <Chapters current={3} total={3} />
        <Typography variant="paragraph-large">
          <Trans.General.ThanksSubCaption />
        </Typography>
      </section>
    </StepView>
  );
}

export function getSteps({
  answers,
  patient,
  showIntro,
}: {
  answers: Answers;
  patient: Patient;
  showIntro: boolean;
}) {
  const steps: Step[] = [];
  // lifestyle chapter
  if (showIntro) {
    LifeStyleIntro.filter(steps);
  }
  Diet.filter(steps, answers);
  StrengthExercise.filter(steps);
  CardioExercise.filter(steps);
  Smoking.filter(steps, answers);
  if (patient.preferredCountry === Country.Sweden) {
    Snus.filter(steps, answers);
  }
  Drinking.filter(steps, answers);
  Sunburn.filter(steps);

  // family chapter
  FamilyIntro.filter(steps);
  Heritage.filter(steps, answers);
  Cardiovascular.filter(steps, answers);
  SkinCancer.filter(steps, answers);
  Diabetes.filter(steps, answers);
  RelevantConditions.filter(steps, answers);

  // medical history chapter
  MedicalHistoryIntro.filter(steps);
  if (patient.sex !== Sex.Male) {
    Pregnant.filter(steps);
  }
  Allergies.filter(steps, answers);
  Medications.filter(steps, answers);
  SkinConditions.filter(steps, answers);
  MedicalHistoryDiabetes.filter(steps, answers);
  BloodPressure.filter(steps);
  CardioConditions.filter(steps, answers);
  HealthConditions.filter(steps);
  OtherHealthConditions.filter(steps);
  HealthProblems.filter(steps);
  AdditionalWorries.filter(steps);

  steps.push(EndStep);

  return steps;
}
