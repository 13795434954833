import { Answers } from "@cur8/questionnaire";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import {
  Medications,
  MedicationsEnum,
  MedicationsSomethingElse,
  MedicationsType,
  MedicationsTypeEnum,
} from "lib/questionnaire/question-flows/onboarding/questions/medication";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { MultiChoiceInput } from "render/ui/presentation/Questionnaire/components/MultiChoiceInput";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function MedicationsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(Medications);
  const label = TransQuestions.Medication.Question();
  const { englishLabel } = TransQuestions.Medication.Question;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={Medications}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(Medications);
      }}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.MedicationExplanation />,
      }}
    >
      <ChoiceInput
        question={Medications}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(Medications, {
            answer,
            questionAnswer: {
              questionId: "medications",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: Medications.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function MedicationsTypeStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(MedicationsType);
  const label = TransQuestions.Medication.TypeQuestion();
  const { englishLabel } = TransQuestions.Medication.TypeQuestion;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={MedicationsType}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(MedicationsType);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.MedicationExplanation />,
      }}
    >
      <MultiChoiceInput
        question={MedicationsType}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(MedicationsType, {
            answer,
            questionAnswer: {
              questionId: "medications.type",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "textarray",
                value: MedicationsType.getValue(answer),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

function MedicationsSomethingElseStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(MedicationsSomethingElse);
  const label = TransQuestions.Medication.SomethingElseQuestion();
  const { englishLabel } = TransQuestions.Medication.SomethingElseQuestion;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={MedicationsSomethingElse}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(MedicationsSomethingElse);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.MedicationExplanation />,
      }}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(MedicationsSomethingElse, {
            answer,
            questionAnswer: {
              questionId: "medications.somethingElse",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "text",
                value: MedicationsSomethingElse.getValue(answer).trim(),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(MedicationsStep);

  if (answers.lookup(Medications) === MedicationsEnum.Yes) {
    steps.push(MedicationsTypeStep);
  }

  if (
    answers.lookup(MedicationsType)?.includes(MedicationsTypeEnum.SomethingElse)
  ) {
    steps.push(MedicationsSomethingElseStep);
  }
}
