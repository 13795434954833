import { localize } from "render/context/InternationalizationContext";

const General = {
  WhyAreWeAsking: localize({
    en_GB: <>Why do we ask this?</>,
    sv_SE: <>Varför frågar vi det här?</>,
  }),
  WhatDoesThisMean: localize({
    en_GB: "What does this mean?",
    sv_SE: "Vad betyder det här?",
  }),
  WhatCounts: localize({
    en_GB: "What counts?",
    sv_SE: "Vad räknas?",
  }),
  OkIUnderstand: localize({
    en_GB: <>Okay, I understand</>,
    sv_SE: <>Okej, jag förstår</>,
  }),
  WriteYourAnswer: localize({
    en_GB: "Answer here",
    sv_SE: "Svara här",
  }),
  YesWriteHere: localize({
    en_GB: "Yes, write here",
    sv_SE: "Ja, skriv här",
  }),
  YesSubmit: localize({
    en_GB: <>Yes, submit</>,
    sv_SE: <>Ja, skicka in</>,
  }),
  NoSubmit: localize({
    en_GB: <>No, submit</>,
    sv_SE: <>Nej, skicka in</>,
  }),
  NextQuestion: localize({
    en_GB: <>Next Question</>,
    sv_SE: <>Nästa fråga</>,
  }),
  YesNextQuestion: localize({
    en_GB: <>Yes, Next Question</>,
    sv_SE: <>Ja, nästa fråga</>,
  }),
  Continue: localize({
    en_GB: "Continue",
    sv_SE: "Fortsätt",
  }),
  NoNextQuestion: localize({
    en_GB: "No, Next Question",
    sv_SE: "Nej, nästa fråga",
  }),
  Saving: localize({
    en_GB: <>Saving...</>,
    sv_SE: <>Sparar...</>,
  }),
  CloseAndExit: localize({
    en_GB: "Ok, close",
    sv_SE: "OK, jag förstår",
  }),
  ThanksForYourAnswers: localize({
    en_GB: <>Thank you!</>,
    sv_SE: <>Tack för att du delar med dig</>,
  }),
  ThanksSubCaption: localize({
    en_GB: <>Your medical team will review your answers before your visit.</>,
    sv_SE: (
      <>
        Ditt medicinska team kommer att kolla igenom dina svar innan ditt besök.
      </>
    ),
  }),
};

/*

*/

const Steps = {
  AdditionalWorriesExplanation: localize({
    en_GB: (
      <>
        We would like to know if there is anything in particular that you are
        concerned about so that we can help you with it.
      </>
    ),
    sv_SE: (
      <>
        Vi vill gärna veta om det är något speciellt du är orolig över så att vi
        kan hjälpa dig med det.
      </>
    ),
  }),
  AllergyExplanation: localize({
    en_GB: (
      <>
        Allergies are sometimes associated with different types of diseases and
        give the doctor a better understanding of your health.
      </>
    ),
    sv_SE: (
      <>
        Allergier är ibland associerade med olika typer av sjukdomar och ger
        läkaren en bättre förståelse för din hälsa.
      </>
    ),
  }),
  DietExplanation: localize({
    en_GB: (
      <>
        Sharing your dietary habit with your doctor helps them tailor health
        advice linked to your food and nutrition.
      </>
    ),
    sv_SE: (
      <>
        Genom att dela med dig av dina kostvanor så kan din läkare ge personliga
        hälsoråd och en optimal rekommendation till dig.
      </>
    ),
  }),
  DrinkAlcoholExplanation: localize({
    en_GB: (
      <>
        It is important for the doctor to understand your alcohol intake. This
        will give a more accurate assessment of your health.
      </>
    ),
    sv_SE: (
      <>
        Det är viktigt för läkaren att veta hur mycket alkohol du konsumerar.
        Det hjälper läkaren att göra en bättre bedömning av din hälsa.
      </>
    ),
  }),
  DrinkCountExplanation: localize({
    en_GB: (
      <>
        A standard glass of alcohol is usually equivalent to a pint or 500ml can
        of regular beer, a small glass of wine, or 40ml of spirits.
      </>
    ),
    sv_SE: (
      <>
        Ett standardglas alkohol motsvarar 50cl folköl, ett litet glas vin eller
        4cl sprit.
      </>
    ),
  }),
  DrinkingOpenText: localize({
    en_GB: <>How much is a glass?</>,
    sv_SE: <>Hur stort är ett glas?</>,
  }),
  StrengthExerciseExplanation: localize({
    en_GB: (
      <>
        Strength exercises build muscles and include squats, push-ups, pilates,
        and training using weights.
      </>
    ),
    sv_SE: (
      <>
        Styrketräning inkluderar övningar som bygger muskler, exempelvis benböj,
        armhävningar, pilates och träning med vikter som motstånd.
      </>
    ),
  }),
  CardioExerciseExplanation: localize({
    en_GB: (
      <>
        Cardiovascular exercises elevate the heart rate and include running,
        cycling, hiking, and swimming.
      </>
    ),
    sv_SE: (
      <>
        Konditionsträning höjer pulsen och inkluderar exempelvis löpning,
        cykling, vandring och simning.
      </>
    ),
  }),
  FamilyConditionOpenText: localize({
    en_GB: <>Who counts as family?</>,
    sv_SE: <>Vilka räknas som familj?</>,
  }),
  FamilyConditionExplanation: localize({
    en_GB: <>Siblings, parents, grandparents and parents' siblings.</>,
    sv_SE: (
      <>Syskon, föräldrar, mor- och farföräldrar samt föräldrars syskon.</>
    ),
  }),
  HeritageExplanation: localize({
    en_GB: (
      <>
        Many diseases have a higher prevalence in certain parts of the world,
        which could be due to genetics.
      </>
    ),
    sv_SE: (
      <>
        Många sjukdomar har högre prevalens i vissa delar av världen, mestadels
        pga genetik.
      </>
    ),
  }),
  MedicationExplanation: localize({
    en_GB: (
      <>
        Medications can sometimes cause side effects and affect test results.
        The doctor needs to know this when assessing your health.
      </>
    ),
    sv_SE: (
      <>
        Mediciner kan ibland ge biverkningar och påverka testresultaten. Det är
        något som läkaren behöver veta när dom gör en bedömning av din hälsa.
      </>
    ),
  }),
  MedicationAdd: localize({
    en_GB: <>Add medication</>,
    sv_SE: <>Lägg till medicin</>,
  }),
  PersonalConditionExplanation: localize({
    en_GB: (
      <>
        To be able to give a good recommendation, it helps to know what problems
        you have had before so that you avoid them again.
      </>
    ),
    sv_SE: (
      <>
        För att kunna ge en bra rekommendation hjälper det att veta vilka
        problem du har haft innan så man undviker dom igen.
      </>
    ),
  }),
  SmokingExplanation: localize({
    en_GB: (
      <>
        It is important for the doctor to understand if you smoke cigarettes or
        tobacco. This will give a more accurate assessment of your health.
      </>
    ),
    sv_SE: (
      <>
        Det är viktigt för läkaren att veta om du röker. Det hjälper läkaren att
        göra en bättre bedömning av din hälsa.
      </>
    ),
  }),
  SnusExplanation: localize({
    en_GB: (
      <>
        It is important for the doctor to understand if you use snus. This will
        give a more accurate assessment of your health.
      </>
    ),
    sv_SE: (
      <>
        Det är viktigt för läkaren att veta om du snusar. Det hjälper läkaren
        att göra en bättre bedömning av din hälsa.
      </>
    ),
  }),
  SunburnOpenText: localize({
    en_GB: "What counts as a sunburn?",
    sv_SE: "Hur vet jag om jag har bränt mig i solen?",
  }),
  SunburnExplanation: localize({
    en_GB: (
      <>
        A sunburn is when you experience painful redness of the skin. This is
        important for the doctor to understand your sun habits.
      </>
    ),
    sv_SE: (
      <>
        Om man upplever en smärtsam rödhet i huden efter att ha varit i solen så
        räknas det som att bränna sig. Det är viktigt för läkaren att känna till
        dina solvanor, då det påverkar bedömningen.{" "}
      </>
    ),
  }),
  CardioVascularExplanation: localize({
    en_GB: (
      <>
        Cardiovascular disease affects the heart. Some examples are stroke,
        heart failure, and heart murmurs.
      </>
    ),
    sv_SE: (
      <>
        Kardiovaskulära sjukdomar påverkar hjärta och kärl. Några exempel är
        stroke, hjärtsvikt och blåsljud på hjärtat.
      </>
    ),
  }),
  CardiovascularDetailsExplanation: localize({
    en_GB: (
      <>
        Understanding your family history of cardiovascular diseases helps us
        assess your risk and tailor preventive measures.
      </>
    ),
    sv_SE: (
      <>
        Genom att förstå om någon din familj har drabbats av kardiovaskulära
        sjukdomar så kan vi bedöma din risk bättre och anpassa olika preventiva
        åtgärder för dig.
      </>
    ),
  }),
  SkinCancerExplanation: localize({
    en_GB: (
      <>
        Skin cancers are cancers that start in the skin layer. Some examples are
        melanoma, basalioma, and squamous cell carcinoma.
      </>
    ),
    sv_SE: (
      <>Några exempel på hudcancer är melanom, basaliom och skivepitelcancer.</>
    ),
  }),
  SkinCancerMelanomaExplanation: localize({
    en_GB: (
      <>
        Melanoma is a type of skin cancer that starts in the pigment-making
        cells of the skin.
      </>
    ),
    sv_SE: (
      <>
        Melanom är en typ av hudcancer som börjar i pigmentcellerna i huden. Det
        är den farligaste typen av hudcancer.
      </>
    ),
  }),
  DiabetesExplanation: localize({
    en_GB: (
      <>
        Diabetes is a condition where the body is unable to produce insulin or
        becomes insulin-resistant, thus causing high blood sugar.
      </>
    ),
    sv_SE: (
      <>
        Diabetes är en sjukdom där kroppen antingen inte kan producera eget
        insulin eller där kroppen tappar sin insulinkänslighet. Det leder till
        högre blodsocker.
      </>
    ),
  }),
  DiabetesDetailsExplanation: localize({
    en_GB: (
      <>
        Understanding your family history of diabetes helps us assess your risk
        and tailor preventive measures.
      </>
    ),
    sv_SE: (
      <>
        Genom att förstå om någon din familj har drabbats av diabetes så kan vi
        bedöma din risk bättre och anpassa olika preventiva åtgärder för dig.
      </>
    ),
  }),
  RelevantConditionsExplanation: localize({
    en_GB: (
      <>
        Understanding your family history of certain diseases is important. It
        helps us assess your risk and tailor preventive measures.
      </>
    ),
    sv_SE: (
      <>
        Genom att förstå om någon din familj har drabbats av olika sjukdomar så
        kan vi bedöma din risk bättre och anpassa olika preventiva åtgärder för
        dig.
      </>
    ),
  }),
  PregnantExplanation: localize({
    en_GB: (
      <>
        Understanding if you are pregnant will help us tailor the scan and
        medical advice to your body’s condition and specific needs.
      </>
    ),
    sv_SE: (
      <>
        Den här information hjälper oss att anpassa din scan och medicinska
        rekommendation baserat på dina behov.
      </>
    ),
  }),
  SkinConditionsExplanation: localize({
    en_GB: (
      <>
        Understanding if you have any skin condition can help us assess your
        overall skin type and profile.
      </>
    ),
    sv_SE: (
      <>Den här informationen hjälper oss att bedöma din hälsa och hudtyp.</>
    ),
  }),
  SkinConditionsSkinCancerExplanation: localize({
    en_GB: (
      <>
        People with a previous skin cancer have a higher risk of developing it
        again. This will help us better assess your potential risk.
      </>
    ),
    sv_SE: (
      <>
        Har man haft tidigare hudcancer så löper man högre risk att få det igen.
        Den här informationen hjälper oss att bedöma din potentiella risk
        bättre.
      </>
    ),
  }),
  BloodPressureExplanation: localize({
    en_GB: (
      <>
        This is a condition where the pressure in your blood vessels is
        consistently too high.
      </>
    ),
    sv_SE: <>En sjukdom där trycket i dina kärl konstant är för högt.</>,
  }),
  CardioConditionsExplanation: localize({
    en_GB: (
      <>
        Knowing whether you have these cardiovascular conditions will help the
        doctor better understand your health profile.
      </>
    ),
    sv_SE: (
      <>
        Dessa frågor hjälper din läkare göra en bättre bedömning av din hjärt-
        och kärlhälsa.
      </>
    ),
  }),
  CardioConditionsValvularDiseaseExplanation: localize({
    en_GB: (
      <>
        Understanding your existing conditions can help us assess your risk of
        cardiovascular disease.
      </>
    ),
    sv_SE: (
      <>
        Dessa frågor hjälper din läkare göra en bättre bedömning av din hjärt-
        och kärlhälsa.
      </>
    ),
  }),
  HealthConditionsExplanation: localize({
    en_GB: (
      <>
        Knowing whether you have these health conditions will help the doctor
        better understand your health profile.
      </>
    ),
    sv_SE: (
      <>
        Dessa frågor hjälper din läkare göra en bättre bedömning av din hälsa.
      </>
    ),
  }),
  OtherHealthConditionsExplanation: localize({
    en_GB: (
      <>
        It is important for us to know your medical history comprehensively so
        that we can provide you with care specifically for your needs.
      </>
    ),
    sv_SE: (
      <>
        Dessa frågor hjälper din läkare göra en bättre bedömning av din hälsa.
        På så sätt kan vi ge dig specifika och personliga rekommendationer.
      </>
    ),
  }),
};

export const Trans = { General, Steps };
