import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Patient, Slot } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as EightCornerStarIcon } from "assets/eight-corner-star.svg";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { paths } from "render/routes/paths";
import { ReactComponent as ArrowIcon } from "render/ui/icons/arrow.svg";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { FadeIn } from "render/views/HomeView/components/_shared/FadeIn/FadeIn";

interface VisitSummaryReadyStageProps {
  patient: Patient;
  bookingTokens: APITypesV2.BookingTokenV2[];
  attendedVisitSlot: Slot;
  upcomingVisitSlot: Slot | undefined;
}

export function VisitSummaryReadyStage({
  patient,
  attendedVisitSlot,
  upcomingVisitSlot,
  bookingTokens,
}: VisitSummaryReadyStageProps) {
  const nav = {
    report: useNav(paths.appointmentSummary),
    booking: useNav(paths.booking),
    payment: useNav(paths.checkout),
    appointment: useNav(paths.appointment),
  };
  const onBookClick = useCallback(() => {
    if (bookingTokens.length === 0) {
      nav.payment.go({});
    } else {
      nav.booking.go({});
    }
  }, [bookingTokens, nav.booking, nav.payment]);

  const onAppointmentInfoClick = useCallback(() => {
    if (!upcomingVisitSlot) {
      return;
    }

    nav.appointment.go({ slotId: upcomingVisitSlot.slotId });
  }, [nav.appointment, upcomingVisitSlot]);

  const time = useMemo(() => {
    if (!upcomingVisitSlot) {
      return undefined;
    }

    const format = "HH:mm";
    const timeZoneId = upcomingVisitSlot.room?.site?.timeZoneId;
    const start = upcomingVisitSlot.startTime.setZone(timeZoneId);
    const end = upcomingVisitSlot.endTime.setZone(timeZoneId);

    return `${start.toFormat(format)} - ${end.toFormat(format)}`;
  }, [upcomingVisitSlot]);
  const date = useMemo(() => {
    if (!upcomingVisitSlot) {
      return undefined;
    }

    const timeZoneId = upcomingVisitSlot.room?.site?.timeZoneId;
    const start = upcomingVisitSlot.startTime.setZone(timeZoneId);
    const isDifferentYear = start.year !== DateTime.now().year;

    return start.toFormat(`d MMMM${isDifferentYear ? " yyyy" : ""}`);
  }, [upcomingVisitSlot]);

  const canBook = useMemo(() => {
    return patient.flags.includes(APITypesV1.PatientFlag.CanBook);
  }, [patient.flags]);

  return (
    <div>
      <FadeIn offset={112}>
        <Typography variant="title-large">
          <Trans.Greeting patient={patient} />
        </Typography>
      </FadeIn>
      <div className={styles.cta}>
        <FadeIn offset={176}>
          <button className={styles.button} onClick={nav.report.on({})}>
            <EightCornerStarIcon />
            <div className={styles.text}>
              <Typography variant="cta" color="subtle">
                <Trans.HealthReport />
              </Typography>
              <Typography variant="paragraph-sub" color="subtle">
                {attendedVisitSlot?.startTime
                  .setZone(attendedVisitSlot.room?.site?.timeZoneId)
                  .toFormat("d MMMM yyyy")}
              </Typography>
            </div>
          </button>
        </FadeIn>

        {upcomingVisitSlot && (
          <FadeIn offset={176}>
            <button className={styles.button} onClick={onAppointmentInfoClick}>
              <ArrowIcon style={{ transform: "rotate(180deg)" }} />
              <div className={styles.text}>
                <Typography variant="cta" color="subtle">
                  <Trans.ScanBooked />
                </Typography>
                <Typography variant="paragraph-sub" color="subtle">
                  {time} • {date}
                </Typography>
              </div>
            </button>
          </FadeIn>
        )}
        {canBook && !upcomingVisitSlot && (
          <FadeIn offset={176}>
            <button className={styles.button} onClick={onBookClick}>
              <ArrowIcon style={{ transform: "rotate(180deg)" }} />
              <div className={styles.text}>
                <Typography variant="cta" color="subtle">
                  <Trans.Resever.Title />
                </Typography>
                <Typography variant="paragraph-sub" color="subtle">
                  <Trans.Resever.Description />
                </Typography>
              </div>
            </button>
          </FadeIn>
        )}
      </div>
    </div>
  );
}
