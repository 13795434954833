import { useQueryParams } from "@pomle/react-router-paths";
import { queries } from "render/routes/paths";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useWeightMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricWeightDetails() {
  const { data } = useWeightMetric();

  const [params, setParams] = useQueryParams(queries.metric);
  const numOfScans = data?.length || 1;
  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });

  if (!data) {
    return undefined;
  }

  const { cur: currentWeight, prev: previous } = getMetricHistory(
    data,
    scanNum
  );

  const values = {
    subLabel: "kg",
    value: currentWeight?.unit["kilograms"],
    previousValue: previous?.unit["kilograms"],
    metricRating: undefined,
    scanDate: currentWeight?.measurement.timestampStart,
    ranges: undefined,
  };

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
    />
  );
}
