import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { Direction, Slide } from "@pomle/react-viewstack-transitions";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-36x36x.svg";
import { TransStudies } from "lib/i18n/translations/studies";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useValidPatientConsentsQuery } from "render/hooks/api/queries/useValidPatientConsentsQuery";
import { useGiveConsentMutation } from "render/hooks/mutations/consent/useGiveConsentMutation";
import { useRevokeConsentMutation } from "render/hooks/mutations/consent/useRevokeConsentMutation";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useTracking } from "render/hooks/useTracking";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Toggle } from "render/ui/presentation/Toggle";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { onboardingEvent } from "render/views/OnboardingView/tracking";
import { ConsentDocument } from "render/views/ProfileView/components/ConsentDocument";
import {
  Study,
  useConsentsMap,
} from "render/views/ProfileView/components/DataAndPrivacySection/hooks/useConsentsMap";
import { SharedTrans } from "render/views/trans";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface OnboardingStudiesConsentViewProps {
  onSave: () => void;
  onClose?: () => void;
}

export function AtriumOnboardingStudiesConsentView({
  onSave,
  onClose,
}: OnboardingStudiesConsentViewProps) {
  const { trackEvent } = useTracking();
  const contactUsPopup = useContactUsPopup();
  const { enqueueSnackbar } = useSnackbar();
  const patientConsentsQuery = useValidPatientConsentsQuery();
  const { studies } = useConsentsMap();
  const [study, setStudy] = useState<Study>();
  const { mutate: giveStudyConsentMutation } = useGiveConsentMutation();
  const { mutate: revokeStudyConsentMutation } = useRevokeConsentMutation();

  const toggleStudyConsent = useAsyncHandle(
    async (study: string, newConsentValue: boolean) => {
      try {
        if (newConsentValue) {
          giveStudyConsentMutation({
            documentUri: study,
            consentMetadataId: undefined,
          });
        } else {
          const consent = patientConsentsQuery.data?.find(
            ({ documentUrl }) => documentUrl === study
          );
          if (!consent) {
            throw new Error("Consent not found");
          }
          revokeStudyConsentMutation({ consentId: consent.id });
        }
      } catch (e) {
        enqueueSnackbar(<Trans.Error.FailedToGiveConsent />, {
          variant: "error",
        });
        throw e;
      }
    }
  );

  const selectedStudy = useMemo(() => {
    if (!patientConsentsQuery.data) {
      return undefined;
    }
    if (!study) {
      return undefined;
    }
    return patientConsentsQuery.data.find(({ documentUrl }) =>
      documentUrl.includes(study.name)
    );
  }, [patientConsentsQuery.data, study]);

  const dermaFlowStudy = useMemo(
    () =>
      patientConsentsQuery?.data?.find(({ documentUrl }) =>
        documentUrl.includes(studies.DERMAFLOW.name)
      ),
    [patientConsentsQuery, studies.DERMAFLOW]
  );

  const spectrumStudy = useMemo(
    () =>
      patientConsentsQuery?.data?.find(({ documentUrl }) =>
        documentUrl.includes(studies.SPECTRUM.name)
      ),
    [patientConsentsQuery, studies.SPECTRUM]
  );

  const cardioStudy = useMemo(
    () =>
      patientConsentsQuery?.data?.find(({ documentUrl }) =>
        documentUrl.includes(studies.CARDIO.name)
      ),
    [patientConsentsQuery, studies.CARDIO]
  );

  const onCloseStudyDetail = () => {
    patientConsentsQuery.refetch();
    setStudy(undefined);
  };

  const hasAgreedToAnyStudy = useMemo(
    () =>
      dermaFlowStudy !== undefined ||
      spectrumStudy !== undefined ||
      cardioStudy !== undefined,
    [dermaFlowStudy, spectrumStudy, cardioStudy]
  );

  const [toggleState, setToggleState] = useState({
    [studies.DERMAFLOW.name]: dermaFlowStudy !== undefined,
    [studies.SPECTRUM.name]: spectrumStudy !== undefined,
    [studies.CARDIO.name]: cardioStudy !== undefined,
  });

  useEffect(() => {
    setToggleState({
      [studies.DERMAFLOW.name]: dermaFlowStudy !== undefined,
      [studies.SPECTRUM.name]: spectrumStudy !== undefined,
      [studies.CARDIO.name]: cardioStudy !== undefined,
    });
  }, [dermaFlowStudy, spectrumStudy, cardioStudy, studies]);

  const toggleHandler = useCallback(
    async (study: string, value: boolean) => {
      setToggleState((prev) => ({
        ...prev,
        [study]: value,
      }));

      try {
        await toggleStudyConsent.callback(study, value);
      } catch (e) {
        setToggleState((prev) => ({
          ...prev,
          [study]: !value,
        }));
      }
    },
    [toggleStudyConsent]
  );

  const handleToggle = useAsyncHandle(toggleHandler);

  const handleSubmit = async () => {
    onSave();
  };

  return (
    <FullScreenPageLayout>
      <ViewStack>
        <BurgerLayout>
          <LogoHeader
            leftElement={
              onClose && <IconButton onClick={onClose} icon={<CrossIcon />} />
            }
            rightElement={
              <IconButton
                ariaLabel={SharedTrans.ContactUs()}
                icon={<QuestionIcon display="block" />}
                onClick={() => {
                  trackEvent(onboardingEvent.openContactsSidebarClick("open"));
                  contactUsPopup.emit();
                }}
              />
            }
            hideLogo
          />

          <div className={styles.body}>
            <div className={styles.content}>
              <Typography variant="title-large">
                <Trans.StudyConsent.Title />
              </Typography>
              <Typography style={{ marginTop: "16px" }} variant="paragraph-sub">
                <Trans.StudyConsent.Paragraph1 />
              </Typography>
              <Typography style={{ marginTop: "8px" }} variant="paragraph-sub">
                <Trans.StudyConsent.Paragraph2 />
              </Typography>

              <ul className={styles.studies}>
                <li className={styles.study}>
                  <Toggle
                    name="consent"
                    label=""
                    checked={toggleState[studies.DERMAFLOW.name]}
                    onClick={(value) => {
                      handleToggle.callback(
                        studies.DERMAFLOW.getUrl("en"),
                        value
                      );
                    }}
                  />
                  <span
                    onClick={() => {
                      setStudy(studies.DERMAFLOW);
                    }}
                  >
                    <TransStudies.DermaFlow.Title />
                  </span>
                </li>
                <li className={styles.study}>
                  <Toggle
                    name="consent"
                    label=""
                    checked={toggleState[studies.SPECTRUM.name]}
                    onClick={(value) => {
                      handleToggle.callback(
                        studies.SPECTRUM.getUrl("en"),
                        value
                      );
                    }}
                  />
                  <span
                    onClick={() => {
                      setStudy(studies.SPECTRUM);
                    }}
                  >
                    <TransStudies.Spectrum.Title />
                  </span>
                </li>
                <li className={styles.study}>
                  <Toggle
                    name="consent"
                    label=""
                    checked={toggleState[studies.CARDIO.name]}
                    onClick={(value) => {
                      handleToggle.callback(studies.CARDIO.getUrl("en"), value);
                    }}
                  />
                  <span
                    onClick={() => {
                      setStudy(studies.CARDIO);
                    }}
                  >
                    <TransStudies.Cardio.Title />
                  </span>
                </li>
              </ul>

              <Typography variant="paragraph-sub">
                <Trans.StudyConsent.ParticipationInfo />
              </Typography>
            </div>
          </div>

          <div className={styles.cta}>
            <div className={styles.action}>
              <ActionButton
                onClick={handleSubmit}
                disabled={!hasAgreedToAnyStudy}
              >
                <Trans.StudyConsent.ConsentAndParticipateButton />
              </ActionButton>
            </div>
          </div>
        </BurgerLayout>
        <Slide active={study != null} direction={Direction.Right}>
          <Sticky>
            <ConsentDocument
              onToggle={() => {}}
              key={study?.name}
              study={selectedStudy}
              onClose={onCloseStudyDetail}
              documentUrl={study?.getUrl("en")}
            />
          </Sticky>
        </Slide>
      </ViewStack>
    </FullScreenPageLayout>
  );
}
