import { AnklePressure } from "@cur8/health-risks-calc";
import { PathLink } from "@pomle/react-router-paths";
import { Metric } from "lib/api/metrics";
import { paths } from "render/routes/paths";
import { pickHighestSide } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricAnklePressure/highestSide";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useAnklePressureMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric as MetricLayout, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

const getRiskForHighestSide = (
  highestSide: "left" | "right",
  leftAnklePressure?: Metric<"cardio.ankle_pressure.left">,
  rightAnkePressure?: Metric<"cardio.ankle_pressure.right">
) => {
  const riskRanges = AnklePressure.rangesFor();
  if (leftAnklePressure && rightAnkePressure) {
    return riskRanges.findRisk(
      highestSide === "left" ? leftAnklePressure.unit : rightAnkePressure.unit
    );
  } else if (leftAnklePressure) {
    return riskRanges.findRisk(leftAnklePressure.unit);
  } else if (rightAnkePressure) {
    return riskRanges.findRisk(rightAnkePressure.unit);
  }
};

export function MetricAnklePressure() {
  const { data, isLoading: isDataLoading } = useAnklePressureMetric();

  if (isDataLoading) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!data) {
    return null;
  }

  const { cur: currentLeftAnklePressure, prev: prevLeftAnklePressure } =
    getMetricHistory(data.left);
  const { cur: currentRightAnklePressure, prev: prevRightAnklePressure } =
    getMetricHistory(data.right);

  const { anklePressure: highestAnklePressure, side: highestSide } =
    pickHighestSide({
      left: currentLeftAnklePressure,
      right: currentRightAnklePressure,
    });
  const highestPrevAnklePressure =
    highestSide === "left" ? prevLeftAnklePressure : prevRightAnklePressure;

  const metricRating = getRiskForHighestSide(
    highestSide,
    currentLeftAnklePressure,
    currentRightAnklePressure
  );

  return (
    <PathLink to={paths.anklePressureMetric.url({})}>
      <MetricLayout
        label={<Trans.MetricName />}
        metricRating={metricRating}
        value={highestAnklePressure?.unit.mmHg}
        subLabel={<Trans.MetricUnit />}
        previousValue={highestPrevAnklePressure?.unit.mmHg}
      />
    </PathLink>
  );
}
