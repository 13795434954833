import { Answers } from "@cur8/questionnaire";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import {
  MedicalHistoryDiabetes,
  MedicalHistoryDiabetesComplications,
  MedicalHistoryDiabetesEnum,
  MedicalHistoryDiabetesType,
} from "lib/questionnaire/question-flows/onboarding/questions/medicalHistoryDiabetes";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { ChoiceInputWithDescriptions } from "render/ui/presentation/Questionnaire/components/ChoiceInputWithDescriptions";
import { MultiChoiceInputWithDescriptions } from "render/ui/presentation/Questionnaire/components/MultiChoiceInputWithDescriptions";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function DiabetesStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(MedicalHistoryDiabetes);
  const label = TransQuestions.MedicalHistoryDiabetes.Question();
  const { englishLabel } = TransQuestions.MedicalHistoryDiabetes.Question;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={MedicalHistoryDiabetes}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(MedicalHistoryDiabetes);
      }}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.CardioConditionsExplanation />,
      }}
    >
      <ChoiceInput
        question={MedicalHistoryDiabetes}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(MedicalHistoryDiabetes, {
            answer,
            questionAnswer: {
              questionId: "medicalHistoryDiabetes",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: MedicalHistoryDiabetes.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function DiabetesTypeStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(MedicalHistoryDiabetesType);
  const label = TransQuestions.MedicalHistoryDiabetes.TypeQuestion();
  const { englishLabel } = TransQuestions.MedicalHistoryDiabetes.TypeQuestion;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={MedicalHistoryDiabetesType}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(MedicalHistoryDiabetesType);
      }}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: (
          <Trans.Steps.CardioConditionsValvularDiseaseExplanation />
        ),
      }}
    >
      <ChoiceInputWithDescriptions
        question={MedicalHistoryDiabetesType}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(MedicalHistoryDiabetesType, {
            answer,
            questionAnswer: {
              questionId: "medicalHistoryDiabetes.type",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: MedicalHistoryDiabetesType.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function DiabetesComplicationsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(MedicalHistoryDiabetesComplications);
  const label = TransQuestions.MedicalHistoryDiabetes.ComplicationsQuestion();
  const { englishLabel } =
    TransQuestions.MedicalHistoryDiabetes.ComplicationsQuestion;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={MedicalHistoryDiabetesComplications}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(MedicalHistoryDiabetesComplications);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: (
          <Trans.Steps.CardioConditionsValvularDiseaseExplanation />
        ),
      }}
    >
      <MultiChoiceInputWithDescriptions
        question={MedicalHistoryDiabetesComplications}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(MedicalHistoryDiabetesComplications, {
            answer,
            questionAnswer: {
              questionId: "medicalHistoryDiabetes.complications",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "textarray",
                value: MedicalHistoryDiabetesComplications.getValue(answer),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(DiabetesStep);

  if (
    answers.lookup(MedicalHistoryDiabetes) === MedicalHistoryDiabetesEnum.Yes
  ) {
    steps.push(DiabetesTypeStep);
    steps.push(DiabetesComplicationsStep);
  }
}
