import { Action, Event } from "render/hooks/useTracking";

const category = "PostScanIntroView";

export const postScanIntroEvent = {
  pageView: (): Event => {
    return {
      category,
      action: Action.View,
    };
  },
  shareClick: (value: "nativeShare" | "download"): Event => {
    return {
      category,
      action: Action.Click,
      label: "share_button",
      value: value,
    };
  },
  continueClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "continue_button",
    };
  },
};
