import { NonHDL } from "@cur8/health-risks-calc";
import { PathLink } from "@pomle/react-router-paths";
import { calcAge } from "lib/age";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { paths } from "render/routes/paths";
import { useIsScore2HighRisk } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/hooks/useIsScore2HighRisk";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useNonHdlMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricBloodworkNonHdl() {
  const { data: patient, isLoading: isPatientLoading } = usePatientQuery();
  const { data, isLoading: isDataLoading } = useNonHdlMetric();
  const isScore2HighRisk = useIsScore2HighRisk();

  if (isPatientLoading || isDataLoading) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!data || !patient) {
    return null;
  }

  const { cur, prev } = getMetricHistory(data);
  const age = patient.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;
  const riskRanges = NonHDL.rangesFor({
    age,
    sex: patient.sex,
    isScore2HighRisk: isScore2HighRisk || false,
  });
  const metricRating = cur && riskRanges.findRisk(cur.unit);
  const value = cur?.unit["mmol/L"];
  const prevValue = prev?.unit["mmol/L"];

  return (
    <PathLink to={paths.nonHDLMetric.url({})}>
      <Metric
        label={<Trans.MetricName />}
        metricRating={metricRating}
        value={value}
        subLabel={<Trans.MetricUnit />}
        previousValue={prevValue}
      />
    </PathLink>
  );
}
