import { Risk } from "@cur8/health-risks-calc";
import { GripStrength as GripStrengthReference } from "@cur8/reference-data";
import { Sex } from "@cur8/rich-entity";
import { PathLink } from "@pomle/react-router-paths";
import { calcAge } from "lib/age";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { paths } from "render/routes/paths";
import { pickHighestSide } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGripStrength/highestSide";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useGripStrengthMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

function inRange(val: number, start: number, end: number) {
  return val >= start && val <= end;
}

export function MetricGripStrength() {
  const { data: patient, isLoading: isPatientLoading } = usePatientQuery();
  const { data, isLoading: isDataLoading } = useGripStrengthMetric();

  if (isPatientLoading || isDataLoading) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!data || !patient) {
    return null;
  }

  const { cur: currentLeft, prev: prevLeft } = getMetricHistory(data.left);
  const { cur: currentRight, prev: prevRight } = getMetricHistory(data.right);

  const { gripStrength: currentHighestGripStrength, side: currentHighestSide } =
    pickHighestSide({
      left: currentLeft,
      right: currentRight,
    });
  const prevHighestGripStrength =
    currentHighestSide === "left" ? prevLeft : prevRight;

  const age = patient?.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;

  const gripStrengthReference =
    patient?.sex === Sex.Female
      ? GripStrengthReference.Female
      : GripStrengthReference.Male;

  const reference = gripStrengthReference.find((chunk) =>
    inRange(age, chunk.age.from, chunk.age.to)
  );

  const offset =
    currentHighestGripStrength !== undefined &&
    gripStrengthReference &&
    reference
      ? currentHighestGripStrength.unit.kilograms - reference?.dominant.average
      : NaN;

  const metricRating = offset < 0 ? Risk.Risk : Risk.Normal;

  return (
    <PathLink to={paths.gripStrengthMetric.url({})}>
      <Metric
        label={<Trans.MetricName />}
        subLabel={<Trans.MetricUnit />}
        metricRating={metricRating}
        value={currentHighestGripStrength?.unit.kilograms}
        previousValue={prevHighestGripStrength?.unit.kilograms}
      />
    </PathLink>
  );
}
