import { VisitSummary } from "@cur8/rich-entity";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSession } from "render/context/MSALContext";
import { isRelevantVisit } from "render/context/PostScanIntroContext/libs";
import { useAppointmentSummariesQuery } from "render/hooks/api/queries/useAppointmentSummariesQuery";
import { LogoView } from "render/views/LogoView";
import { PostScanIntroView } from "render/views/PostScanIntroView";
import { useLocalStorage } from "usehooks-ts";
import {
  PostScanIntroStorageRecord,
  defaultStorageRecord,
  storageRecordDeserializer,
} from "./storage";

type PostScanIntroContextValue = {
  isOpen: boolean | undefined;
  latestVisit: VisitSummary | undefined;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  markAsSeen: () => void;
};

const Context = createContext<PostScanIntroContextValue | null>(null);

interface PostScanIntroContextProps {
  children: React.ReactNode;
}

export function PostScanIntroContext({ children }: PostScanIntroContextProps) {
  const [storageRecord, setStorageRecord] =
    useLocalStorage<PostScanIntroStorageRecord>(
      "PostScanIntro",
      defaultStorageRecord,
      {
        deserializer: storageRecordDeserializer,
      }
    );

  const { mfa } = useSession();

  const visitSummaries = useAppointmentSummariesQuery({
    enabled: mfa,
  });

  const latestVisit = visitSummaries?.data?.at(0);

  const shouldShowIntro = useMemo(() => {
    // we cannot fetch summaries from the API without having an MFA verified session.
    if (!mfa) {
      return false;
    }

    if (!visitSummaries.isFetched) {
      return undefined;
    }
    // note(ford): member has no visit summaries or the request failed.
    //             we skip the post-scan intro so we do not brick the app
    if (!latestVisit?.visitId) {
      return false;
    }

    if (!isRelevantVisit(latestVisit)) {
      return false;
    }

    if (storageRecord.lastSeenVisitId === latestVisit.visitId) {
      return false;
    }

    return true;
  }, [mfa, visitSummaries, storageRecord, latestVisit]);

  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isOpen !== undefined) {
      return;
    }

    if (shouldShowIntro === undefined) {
      return;
    }

    setIsOpen(shouldShowIntro);
  }, [isOpen, shouldShowIntro]);

  const markAsSeen = useCallback(() => {
    setStorageRecord((value) => {
      if (latestVisit?.visitId) {
        value.lastSeenVisitId = latestVisit?.visitId;
      }

      return value;
    });
  }, [latestVisit, setStorageRecord]);

  const value = {
    isOpen,
    latestVisit,
    setIsOpen,
    markAsSeen,
  };

  return (
    <Context.Provider value={value}>
      {isOpen === undefined && <LogoView />}
      {isOpen === true && <PostScanIntroView />}
      {isOpen === false && <>{children}</>}
    </Context.Provider>
  );
}

export function usePostScanIntro() {
  const context = useContext(Context);

  if (!context) {
    throw new Error("usePostScanIntro without PostScanIntroContext");
  }

  return context;
}
