import { useNav, useRouter } from "@pomle/react-router-paths";
import { useEffect, useMemo } from "react";
import { useLatestAttendedVisitQuery } from "render/hooks/api/queries/useLatestAttendedVisitQuery";
import { useLatestUpcomingVisitQuery } from "render/hooks/api/queries/useLatestUpcomingVisitQuery";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";

export function OnboardingView() {
  const nav = {
    home: useNav(paths.root),
    appointment: useNav(paths.appointmentPrereq.root),
  };
  const { history } = useRouter();
  const latestUpcomingVisitQuery = useLatestUpcomingVisitQuery();
  const latestAttendedVisitQuery = useLatestAttendedVisitQuery();
  const visitsFetched =
    latestAttendedVisitQuery.isFetched && latestUpcomingVisitQuery.isFetched;

  const slotId = useMemo(() => {
    if (!visitsFetched) {
      return;
    }
    return (
      latestUpcomingVisitQuery.data?.slotId ??
      latestAttendedVisitQuery.data?.slotId
    );
  }, [
    latestUpcomingVisitQuery.data,
    latestAttendedVisitQuery.data,
    visitsFetched,
  ]);

  useEffect(() => {
    if (!visitsFetched) {
      return;
    }
    if (!slotId) {
      history.replace(nav.home.to({}));
      return;
    } else {
      history.replace(nav.appointment.to({ slotId }));
    }
  }, [history, nav.appointment, nav.home, slotId, visitsFetched]);

  return <FullScreenPageLayout />;
}
