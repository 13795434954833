import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Patient, Slot } from "@cur8/rich-entity";
import { useNav, useQueryParams, useRouter } from "@pomle/react-router-paths";
import { ReactComponent as ArrowBigIcon } from "assets/arrow-big.svg";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useSession } from "render/context/MSALContext";
import { useAppointmentQuery } from "render/hooks/api/queries/useAppointmentQuery";
import { useBookingTokens } from "render/hooks/api/queries/useBookingTokens";
import { useInvitationCodesQuery } from "render/hooks/api/queries/useInvitationCodesQuery";
import { useLatestAttendedVisitQuery } from "render/hooks/api/queries/useLatestAttendedVisitQuery";
import { useLatestUpcomingVisitQuery } from "render/hooks/api/queries/useLatestUpcomingVisitQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useTracking } from "render/hooks/useTracking";
import { paths, queries } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { Typography } from "render/ui/presentation/Typography";
import { BookStage } from "./components/BookStage";
import { BookingUpcomingStage } from "./components/BookingUpcomingStage";
import { GiveConsentStage } from "./components/GiveConsentStage";
import { Header } from "./components/Header";
import { VisitSummaryReadyStage } from "./components/VisitSummaryReadyStage";
import styles from "./styles.module.sass";
import { homeEvent } from "./tracking";
import { Trans } from "./trans";
import { FadeIn } from "render/views/HomeView/components/_shared/FadeIn";

interface HomeViewContentRendererProps {
  patient: Patient;
  bookingTokens: APITypesV2.BookingTokenV2[];
  attendedVisitSlot: Slot | undefined;
  upcomingVisitSlot: Slot | undefined;
}

function HomeViewContentRenderer({
  patient,
  attendedVisitSlot,
  upcomingVisitSlot,
  bookingTokens,
}: HomeViewContentRendererProps) {
  const { consent } = patient;

  const showConsentScreen = useMemo(() => {
    const hasConsent = consent.createdAt;
    const consentHasExpired =
      consent.expiresAt && consent.expiresAt < DateTime.now();
    return !hasConsent || consentHasExpired;
  }, [consent]);

  if (
    showConsentScreen &&
    patient.primaryConsentRuleset === APITypesV1.ConsentRuleset.Gdpr
  ) {
    return <GiveConsentStage />;
  }

  if (attendedVisitSlot) {
    return (
      <VisitSummaryReadyStage
        attendedVisitSlot={attendedVisitSlot}
        upcomingVisitSlot={upcomingVisitSlot}
        bookingTokens={bookingTokens}
        patient={patient}
      />
    );
  }
  if (showConsentScreen) {
    return <GiveConsentStage />;
  }

  if (upcomingVisitSlot) {
    return <BookingUpcomingStage patient={patient} slot={upcomingVisitSlot} />;
  } else {
    return <BookStage bookingTokens={bookingTokens} patient={patient} />;
  }
}

interface ViewData {
  patient: Patient;
  bookingTokens: APITypesV2.BookingTokenV2[];
  latestUpcomingVisitSlot: Slot | undefined;
  latestAttendedVisitSlot: Slot | undefined;
}

export function HomeView() {
  const { trackEvent } = useTracking();
  const { isNewUser } = useSession();
  const [isReady, setIsReady] = useState(false);
  const [params] = useQueryParams(queries.payment);
  const { history } = useRouter();

  const nav = {
    discountTokens: useNav(paths.profileTokens),
    contact: useNav(paths.contact),
    checkout: useNav(paths.checkout, queries.payment),
    idv: useNav(paths.appointmentPrereq.identityVerification),
  };
  const patientQuery = usePatientQuery();
  const bookingTokensQuery = useBookingTokens();
  const latestAttendedVisitQuery = useLatestAttendedVisitQuery();
  const latestUpcomingVisitQuery = useLatestUpcomingVisitQuery();
  const invitationCodeQuery = useInvitationCodesQuery();

  const latestAttendedVisitSlotQuery = useAppointmentQuery(
    { slotId: latestAttendedVisitQuery?.data?.slotId },
    { enabled: latestAttendedVisitQuery.data?.slotId != null }
  );
  const latestUpcomingVisitSlotQuery = useAppointmentQuery(
    { slotId: latestUpcomingVisitQuery?.data?.slotId },
    { enabled: latestUpcomingVisitQuery.data?.slotId != null }
  );

  const viewData = useMemo(() => {
    if (patientQuery.data == null) {
      return;
    }
    if (bookingTokensQuery.data == null) {
      return;
    }
    if (!latestAttendedVisitQuery.isFetched) {
      return;
    }
    if (!latestUpcomingVisitQuery.isFetched) {
      return;
    }
    if (
      latestAttendedVisitQuery.data != null &&
      latestAttendedVisitSlotQuery.data == null
    ) {
      return;
    }
    if (
      latestUpcomingVisitQuery.data != null &&
      latestUpcomingVisitSlotQuery.data == null
    ) {
      return;
    }
    const result: ViewData = {
      bookingTokens: bookingTokensQuery.data,
      patient: patientQuery.data,
      latestAttendedVisitSlot: latestAttendedVisitSlotQuery.data,
      latestUpcomingVisitSlot: latestUpcomingVisitSlotQuery.data,
    };
    return result;
  }, [
    patientQuery.data,
    bookingTokensQuery.data,
    latestAttendedVisitQuery.isFetched,
    latestAttendedVisitQuery.data,
    latestAttendedVisitSlotQuery.data,
    latestUpcomingVisitQuery.isFetched,
    latestUpcomingVisitQuery.data,
    latestUpcomingVisitSlotQuery.data,
  ]);

  const bookingTokensCount = bookingTokensQuery.data
    ? bookingTokensQuery.data?.length
    : undefined;
  const hasCode = params.code.length !== 0;

  useEffect(() => {
    if (isReady) {
      return;
    }
    if (!viewData) {
      return;
    }

    if (!isNewUser && !hasCode) {
      setIsReady(true);
      return;
    }

    if (viewData.latestUpcomingVisitSlot == null && bookingTokensCount === 0) {
      history.replace(nav.checkout.to({}, { code: params.code }));
      return;
    }

    setIsReady(true);
  }, [
    history,
    isReady,
    bookingTokensCount,
    hasCode,
    isNewUser,
    nav.checkout,
    params.code,
    viewData,
  ]);

  const availableDiscountTokensCount = useMemo(() => {
    if (!invitationCodeQuery.data) {
      return;
    }
    let partialDiscounts = 0;
    let fullDiscounts = 0;

    invitationCodeQuery.data
      .filter((token) => {
        return token.isRedeemed === false;
      })
      .forEach((token) => {
        switch (token.discountTokenType) {
          case APITypesV1.DiscountTokenType.SingleFullDiscount:
            fullDiscounts += 1;
            break;
          case APITypesV1.DiscountTokenType.SinglePartialDiscount:
            partialDiscounts += 1;
            break;
        }
      });

    return partialDiscounts || fullDiscounts;
  }, [invitationCodeQuery.data]);

  return (
    <FullScreenPageLayout disableBackground>
      <BurgerLayout>
        {patientQuery.data && <Header patient={patientQuery.data} />}
        <div className={styles.body}>
          <div className={styles.content}>
            {viewData && (
              <>
                <HomeViewContentRenderer
                  patient={viewData.patient}
                  bookingTokens={viewData.bookingTokens}
                  attendedVisitSlot={viewData.latestAttendedVisitSlot}
                  upcomingVisitSlot={viewData.latestUpcomingVisitSlot}
                />
                {availableDiscountTokensCount != null ? (
                  <FadeIn offset={176}>
                    <button
                      className={styles.actionButton}
                      onClick={() => {
                        trackEvent(homeEvent.openInvitesClick());
                        nav.discountTokens.go({});
                      }}
                    >
                      <div>
                        <Typography variant="cta" color="subtle">
                          <Trans.Invite.Title />
                        </Typography>
                        <Typography variant="paragraph-sub" color="subtle">
                          <Trans.Invite.Description
                            tokenCount={availableDiscountTokensCount}
                          />
                        </Typography>
                      </div>
                      <ArrowBigIcon />
                    </button>
                  </FadeIn>
                ) : null}
              </>
            )}
          </div>
        </div>
        <></>
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
