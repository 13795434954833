import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-36x36x.svg";
import { useCallback, useEffect, useMemo } from "react";
import { useMSAL } from "render/context/MSALContext";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths, queries } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface PasskeyResultViewProps {
  slotId: string;
}

export function PasskeyResultView({ slotId }: PasskeyResultViewProps) {
  const contactUsPopup = useContactUsPopup();
  const { stepUpAccess, session } = useMSAL();
  const [params] = useQueryParams(queries.appointmentPrereq);

  const nav = {
    appointment: useNav(paths.appointment),
    prereq: useNav(paths.appointmentPrereq.root, queries.appointmentPrereq),
  };

  const succeeded = useMemo(
    () => session?.authenticationMethods.includes("passkey"),
    [session]
  );

  useEffect(() => {
    if (succeeded) {
      if (params.origin[0] === "prereq") {
        nav.prereq.go({ slotId }, { origin: params.origin });
      } else {
        nav.appointment.go({
          slotId,
        });
      }
    }
  }, [succeeded, nav.appointment, slotId, params.origin, nav.prereq]);

  const handleTryAgain = useCallback(() => {
    stepUpAccess("psk");
  }, [stepUpAccess]);

  const onClose = useCallback(() => {
    if (params.origin[0] === "prereq") {
      nav.prereq.go({ slotId });
    } else {
      nav.appointment.go({ slotId });
    }
  }, [nav.appointment, nav.prereq, params.origin, slotId]);

  if (succeeded !== false) {
    return null;
  }

  return (
    <FullScreenPageLayout>
      <BurgerLayout>
        <LogoHeader
          leftElement={<IconButton onClick={onClose} icon={<CrossIcon />} />}
          rightElement={
            <IconButton
              icon={<QuestionIcon display="block" />}
              onClick={contactUsPopup.emit}
            />
          }
        />
        <div className={styles.body}>
          <div className={styles.content}>
            <Typography variant="title-large">
              <Trans.Title />
            </Typography>
            <Typography style={{ marginTop: "12px" }} variant="paragraph">
              <Trans.Paragraph1 />
            </Typography>
          </div>
        </div>
        <div className={styles.cta}>
          <div className={styles.action}>
            <ActionButton onClick={handleTryAgain} variant="suggestion">
              <Trans.TryAgain />
            </ActionButton>
          </div>
        </div>
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
