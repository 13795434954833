import { Visit } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback, useEffect, useState } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useScanBookedAssetDetailsQuery } from "render/hooks/api/queries/useScanBookedAssetDetailsQuery";
import { useScanBookedAssetQuery } from "render/hooks/api/queries/useScanBookedAssetQuery";
import { useLatestUpcomingVisitQuery } from "render/hooks/api/queries/useLatestUpcomingVisitQuery";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useTimeOut } from "render/hooks/useTimeOut";
import { paths } from "render/routes/paths";
import { FullMonthDay } from "render/ui/format/FullMonthDay/FullMonthDay";
import { TimeHourMinute } from "render/ui/format/TimeHourMinute";
import { useBookSlotMutation } from "render/views/booking/MultiSiteMonthView/hooks/useBookSlotMutation";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { useSlotQuery } from "render/hooks/api/queries/useSlotQuery";
import { useQueryClient } from "@tanstack/react-query";

interface BookingActionsProps {
  slotId: string;
  onSlotIsTaken: (slotId: string) => void;
}

export function BookingActions({ slotId, onSlotIsTaken }: BookingActionsProps) {
  const queryClient = useQueryClient();
  const slotQuery = useSlotQuery(
    { slotId },
    {
      placeholderData: (prev) => prev,
      refetchInterval: (query) => {
        return query.state.data?.isFree ? 3000 : false;
      },
    }
  );
  const slot = slotQuery.data;

  useEffect(() => {
    if (!slot || slot.isFree) {
      return;
    }
    onSlotIsTaken(slot.slotId);
  }, [queryClient, slot, onSlotIsTaken]);

  const { refetch: refetchUpcomingVisitQuery } = useLatestUpcomingVisitQuery({
    enabled: false,
  });

  const [isBooked, setIsBooked] = useState(false);
  const [createdVisit, setCreatedVisit] = useState<Visit>();

  // note(ford): Prefetch the shareable asset as soon as we have a created visit
  useScanBookedAssetDetailsQuery(createdVisit?.visitId, {
    enabled: !!createdVisit,
  });
  useScanBookedAssetQuery(createdVisit?.visitId, {
    enabled: !!createdVisit,
  });

  const bookSlotMutation = useBookSlotMutation();

  const appointment = useNav(paths.appointment);
  const shareBooking = useNav(paths.shareBooking);

  const appInsights = useAppInsights();

  const postBookingNavigation = useTimeOut(1000, () => {
    if (!slot) {
      return;
    }

    // note(ford): Visit was not created in time, navigate to appointment page instead of
    //             showing the shareable asset as it will fail if the visit is not created.
    if (!createdVisit) {
      appointment.go({ slotId: slot.slotId });
    } else {
      shareBooking.go({
        slotId: createdVisit.slotId,
        visitId: createdVisit.visitId,
      });
    }
  });

  useEffect(() => {
    if (!isBooked) {
      return;
    }

    postBookingNavigation.startTimeout();
  }, [isBooked, postBookingNavigation]);

  const handleBookSlot = useAsyncHandle(
    useCallback(async () => {
      if (!slot) {
        return;
      }

      const [result] = await Promise.all([
        bookSlotMutation(slot),
        new Promise((resolve) => {
          window.setTimeout(() => resolve(undefined), 3000);
        }),
      ]);

      let visit = undefined;
      let count = 0;

      do {
        try {
          let iteration = count;
          let [upcomingVisit] = await Promise.all([
            refetchUpcomingVisitQuery(),
            new Promise((resolve) => {
              window.setTimeout(() => resolve(undefined), 500 * iteration);
            }),
          ]);
          if (upcomingVisit.data?.slotId === result.slotId) {
            visit = upcomingVisit.data;
          }
        } catch (e) {
          const exception =
            e instanceof Error
              ? e
              : new Error("Refetching upcoming visits failed in booking flow");
          appInsights.trackException({
            exception: exception,
            properties: {
              description: "Refetching upcoming visits failed in booking flow",
            },
          });
        } finally {
          count += 1;
        }
      } while (!visit && count < 5);

      if (!visit) {
        appInsights.trackException({
          exception: new Error("Visit not created after booking"),
          properties: {
            description: "Refetching upcoming visits failed in booking flow",
          },
        });
      } else {
        setCreatedVisit(visit);
      }

      setIsBooked(true);
    }, [slot, bookSlotMutation, refetchUpcomingVisitQuery, appInsights])
  );

  if (!slot) {
    return undefined;
  }

  const isBooking = handleBookSlot.busy;
  const isOpen = slot.isFree;
  const disableButton = !isOpen || isBooking || isBooked;
  const timeZoneId = slot.room?.site?.timeZoneId;

  return (
    <div className={styles.BookingActions}>
      <div>
        <div className={styles.time}>
          <TimeHourMinute date={slot.startTime} timeZoneId={timeZoneId} /> -{" "}
          <TimeHourMinute date={slot.endTime} timeZoneId={timeZoneId} />
        </div>
        <div className={styles.day}>
          <FullMonthDay date={slot.startTime} timeZoneId={timeZoneId} />
        </div>
        <div className={styles.location}>{slot.room?.site?.siteName}</div>
      </div>

      <button
        disabled={disableButton}
        data-inprogress={isBooking}
        onClick={handleBookSlot.callback}
        className={styles.book}
      >
        {isBooked ? <Trans.Success /> : undefined}
        {isBooking ? <Trans.Booking /> : undefined}
        {!isBooking && isOpen ? <Trans.Book /> : undefined}
        {!isBooking && !isOpen && !isBooked ? (
          <Trans.Error.SlotIsTaken />
        ) : undefined}
      </button>
    </div>
  );
}
