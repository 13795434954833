import { MetricGroup } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/MetricGroup";
import { MetricABI } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricABI";
import { MetricAnklePressure } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricAnklePressure";
import { MetricBMI } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBMI";
import { MetricBasophils } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBasophils";
import { MetricBloodPressure } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodPressure/MetricBloodPressure";
import { MetricBloodworkHdl } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkHdl";
import { MetricBloodworkLdl } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkLdl";
import { MetricBloodworkNonHdl } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkNonHdl";
import { MetricCRP } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricCRP";
import { MetricCholesterol } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricCholesterol";
import { MetricEosinophils } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEosinophils";
import { MetricEyePressure } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEyePressure/MetricEyePressure";
import { MetricGlucose } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGlucose";
import { MetricGripStrength } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGripStrength";
import { MetricHBA1C } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricHBA1C";
import { MetricHaemoglobin } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricHaemoglobin";
import { MetricLymphocytes } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricLymphocytes";
import { MetricNeutrophils } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricNeutrophils";
import { MetricSkinLesions } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricSkinLesions";
import { MetricTriglycerides } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricTriglycerides";
import { MetricWeight } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricWeight";
import { MetricWhiteBLoodCells } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricWhiteBloodCells";
import { MetricBloodOxygen } from "./components/metrics/MetricBloodOxygen";
import { MetricHeartRate } from "./components/metrics/MetricHeartRate";
import { Trans } from "./trans";

type MetricsSectionProps = {
  headerHeight: number;
};

export function MetricsSection({ headerHeight }: MetricsSectionProps) {
  return (
    <>
      <MetricGroup
        title={<Trans.Group.HeartAndCirculation />}
        headerHeight={headerHeight}
      >
        <MetricBloodPressure key="bloodPressure" />
        <MetricAnklePressure key="ankle-pressure" />
        <MetricABI key="abi" />
        <MetricBloodOxygen key="blood-oxygen" />
        <MetricHeartRate key="heartRate" />
      </MetricGroup>

      <MetricGroup
        title={<Trans.Group.BloodValues />}
        headerHeight={headerHeight}
      >
        <MetricCholesterol key="cholesterol" />
        <MetricBloodworkLdl key="bloodworkNonLdl" />
        <MetricBloodworkHdl key="bloodworkHdl" />
        <MetricTriglycerides key="triglycerides" />
        <MetricBloodworkNonHdl key="bloodworkNonHdl" />
        <MetricWhiteBLoodCells key="whiteBloodCells" />
        <MetricNeutrophils key="neutrophils" />
        <MetricBasophils key="basophils" />
        <MetricEosinophils key="eosinophils" />
        <MetricLymphocytes key="lymphocytes" />
        <MetricCRP key="crp" />
        <MetricHBA1C key="hba1c" />
        <MetricHaemoglobin key="hemoglobin" />
        <MetricGlucose key="glucose" />
      </MetricGroup>

      <MetricGroup title={<Trans.Group.Body />} headerHeight={headerHeight}>
        <MetricWeight key="weight" />
        <MetricBMI key="bmi" />
        <MetricGripStrength key="grip" />
        <MetricEyePressure key="eye-pressure" />
      </MetricGroup>

      <MetricGroup title={<Trans.Group.Skin />} headerHeight={headerHeight}>
        <MetricSkinLesions key="lesion-count" />
      </MetricGroup>
    </>
  );
}
