import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string, scanType: string) => [
  "latest-scan",
  patientId,
  scanType,
];
const queryFn =
  (apiClient: APIClient, patientId: string, scanType: string) => async () => {
    return apiClient.scan
      .queryImmutableScans({
        patientId,
        scanType,
        order: APITypesV1.SortOrder.Desc,
        pageSize: 2,
      })

      .result.then((result) => result.items.map(fromAPI.toImmutableScan))
      .then((scans) => [scans[0], scans[1]]);
  };

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useLatestScanQuery<T = Body>(
  scanType: string,
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryKey: queryKey(patientId, scanType),
    queryFn: queryFn(apiClient, patientId, scanType),
  });
}
