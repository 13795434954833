import { useNav } from "@pomle/react-router-paths";
import { usePopup } from "render/context/PopupContext";
import { useGdprExportQuery } from "render/hooks/api/queries/useGdprExportQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useVisitsQuery } from "render/hooks/api/queries/useVisitsQuery";
import { useGdprExportMutation } from "render/hooks/mutations/useGdprExportMutation";
import { paths } from "render/routes/paths";
import { ReactComponent as ArrowIcon } from "render/ui/icons/arrow.svg";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Dialog } from "render/ui/presentation/Dialog";
import { Typography } from "render/ui/presentation/Typography";
import { IconButton } from "render/ui/trigger/IconButton";
import { ConfirmCreation } from "render/views/ProfileView/components/DataAndPrivacySection/components/GdprExport/ConfirmCreation";
import { State } from "./State";
import styles from "./styles.module.sass";
import * as Trans from "./Trans";

const pollInterval = 1000;

export function GdprExport() {
  const nav = {
    dataAndPrivacy: useNav(paths.dataAndPrivacy),
  };
  const { data: visits } = useVisitsQuery();
  const { data: patient } = usePatientQuery();
  const { data: gdprExport, isLoading } = useGdprExportQuery({
    // ideally we want to subscribe to a Server-Sent Event,
    // but for now we can aggressively poll if the member has no scans,
    // since it will be super fast to create the GDPR export.
    // We absolutely do NOT want to poll if they have scans,
    // as then it takes several minutes or even hours
    refetchInterval(query) {
      const data = query.state.data;
      const scanCount = visits?.reduce(
        (sum, v) => sum + Object.keys(v.immutableScansPerformed ?? {}).length,
        0
      );
      if (scanCount || !data || data.completedOn) {
        return false;
      }
      return pollInterval;
    },
  });
  const { mutate } = useGdprExportMutation();
  const popup = usePopup();

  return (
    <FullScreenPageLayout>
      <LogoHeader
        leftElement={
          <IconButton
            onClick={nav.dataAndPrivacy.on({})}
            icon={<ArrowIcon />}
          />
        }
      />
      <div className={styles.body}>
        <div className={styles.text}>
          <Typography variant="title-medium">
            <Trans.Title />
          </Typography>
          <Typography variant="paragraph-medium" color="subtle">
            <Trans.Description />
          </Typography>
          {!gdprExport?.completedOn && (
            <Typography variant="paragraph-medium" color="subtle">
              <Trans.Disclaimer />
            </Typography>
          )}
        </div>
        <State
          create={() => {
            const { emit, close } = popup.create();
            emit(
              <Dialog onClose={close}>
                <ConfirmCreation
                  onCancel={close}
                  onConfirm={() => {
                    close();
                    mutate();
                  }}
                />
              </Dialog>
            );
          }}
          email={patient?.contactDetails.email}
          gdprExport={gdprExport}
          isLoading={isLoading}
        />
      </div>
    </FullScreenPageLayout>
  );
}
