import { record, string } from "@pomle/shapes";

type PostScanIntroStorageRecord = {
  lastSeenVisitId: string | undefined;
};

const validateStorageRecord = record<PostScanIntroStorageRecord>({
  lastSeenVisitId: string(undefined),
});

const defaultStorageRecord = validateStorageRecord({});

function storageRecordDeserializer(
  rawValue: string
): PostScanIntroStorageRecord {
  try {
    const value = JSON.parse(rawValue);

    return validateStorageRecord(value);
  } catch (e) {
    console.warn(
      "failed to deserialize PostScanIntroStorage from local storage"
    );
    return defaultStorageRecord;
  }
}

export {
  defaultStorageRecord,
  storageRecordDeserializer,
  validateStorageRecord,
};
export type { PostScanIntroStorageRecord };
