import { APITypesV2 } from "@cur8/api-client";
import { useNav } from "@pomle/react-router-paths";
import { useLatestOptionalConsentsByType } from "render/hooks/api/queries/usePatientConsentsQuery";
import { paths } from "render/routes/paths";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { ConsentCard } from "render/views/ProfileView/components/DataAndPrivacySection/components/ConsentCard";
import { useConsentsMap } from "./hooks/useConsentsMap";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";

type DataAndPrivacySectionProps = {
  onOpenConsentClick: (id: string) => void;
  consents?: APITypesV2.ConsentSummary[];
};

export function DataAndPrivacySection({
  onOpenConsentClick,
}: DataAndPrivacySectionProps) {
  const patientQuery = usePatientQuery();
  const nav = {
    exportData: useNav(paths.dataAndPrivacyExportData),
  };
  const { other } = useConsentsMap();
  const { data: latestConsents } = useLatestOptionalConsentsByType();
  const hasOtherConsents =
    latestConsents?.other && latestConsents.other.length > 0;
  const hasStudyConsents =
    latestConsents?.studies && latestConsents.studies.length > 0;

  if (latestConsents == null) {
    return undefined;
  }

  return (
    <section data-hj-suppress className={styles.studiesSection}>
      <div className={styles.title}>
        <Typography variant="title-large">
          <Trans.Title />
        </Typography>
        <Typography variant="paragraph">
          <Trans.Paragraph link={other.PRIVACYPOLICY.getUrl("en")} />
        </Typography>
      </div>

      {hasOtherConsents ? (
        <section className={styles.section}>
          <Typography variant="title-medium">
            <Trans.Products />
          </Typography>
          <div className={styles.cardsWrapper}>
            {latestConsents.other.map((consent) => (
              <ConsentCard
                key={consent.relevantSignature?.consentId}
                consent={consent}
                onOpenConsentClick={onOpenConsentClick}
              />
            ))}
          </div>
        </section>
      ) : undefined}

      {hasStudyConsents ? (
        <section>
          <div className={styles.sectionTitle}>
            <Typography variant="title-medium">
              <Trans.ResearchStudies />
            </Typography>
          </div>
          <div className={styles.cardsWrapper}>
            {latestConsents.studies.map((consent) => (
              <ConsentCard
                key={consent.relevantSignature?.consentId}
                consent={consent}
                onOpenConsentClick={onOpenConsentClick}
              />
            ))}
          </div>
        </section>
      ) : undefined}

      <section>
        <div className={styles.sectionTitle}>
          <Typography variant="title-medium">
            <Trans.YourData />
          </Typography>
        </div>
        <div className={styles.cardsWrapper}>
          <ActionButton
            disabled={!patientQuery.data?.hasVerifiedIdentity}
            onClick={nav.exportData.on({})}
          >
            <Trans.ExportYourData />
          </ActionButton>
        </div>
      </section>
    </section>
  );
}
