import { APITypesV1 } from "@cur8/api-client";
import { Study } from "render/views/ProfileView/components/DataAndPrivacySection/hooks/useConsentsMap";

export function findNotParticipatedStudies(
  studies: APITypesV1.ConsentSummary[],
  studyMap: { [x: string]: Study }
) {
  const result: Study[] = [];

  for (const study of Object.values(studyMap)) {
    const has = studies.find((s) => s.documentUrl.includes(study.name));
    if (!has) {
      result.push(study);
    }
  }

  return result;
}
