import { useNav } from "@pomle/react-router-paths";
import { useAppointmentQuery } from "render/hooks/api/queries/useAppointmentQuery";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { AppointmentInfoSection } from "./components/AppointmentInfoSection";
import { useEffect } from "react";

interface AppointmentViewProps {
  slotId: string;
}

export function AppointmentView({ slotId }: AppointmentViewProps) {
  const nav = {
    root: useNav(paths.root),
  };
  const appointmentQuery = useAppointmentQuery({ slotId });

  useEffect(() => {
    if (!appointmentQuery.error) {
      return;
    }

    if (appointmentQuery.data) {
      return;
    }
    nav.root.go({});
  }, [appointmentQuery.data, appointmentQuery.error, nav.root]);

  return (
    <FullScreenPageLayout>
      {appointmentQuery.isFetched && appointmentQuery.data && (
        <AppointmentInfoSection slot={appointmentQuery.data} />
      )}
    </FullScreenPageLayout>
  );
}
