import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Chapters } from "render/views/OnboardingView/components/OnboardingFlowView/components/Questions/components/Chapters";
import { StepView } from "render/views/OnboardingView/components/OnboardingFlowView/components/Questions/components/StepView";
import {
  Step,
  StepProps,
} from "render/views/OnboardingView/components/OnboardingFlowView/components/Questions/step";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

function FamilyIntro({
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
}: StepProps) {
  return (
    <StepView
      cta={
        <ActionButton variant="suggestion" onClick={onNext}>
          <Trans.Button />
        </ActionButton>
      }
      totalQuestionCount={totalQuestions}
      currentQuestion={currentQuestion}
      onPrev={onPrev}
    >
      <section className={styles.content}>
        <div className={styles.text}>
          <Typography variant="title-large">
            <Trans.Title />
          </Typography>
          <Chapters current={2} total={3} />
        </div>
        <div className={styles.text}>
          <Typography variant="paragraph-large">
            <Trans.ContentOne />
          </Typography>
          <Typography variant="paragraph-large">
            <Trans.ContentTwo />
          </Typography>
        </div>
      </section>
    </StepView>
  );
}

export function filter(steps: Step[]) {
  steps.push(FamilyIntro);
}
