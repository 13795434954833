import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-36x36x.svg";
import { useCallback } from "react";
import { useMSAL } from "render/context/MSALContext";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths, queries } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { useVisitPrerequisites } from "render/hooks/api/useVisitPrerequisites";
import { ReactComponent as ClockSmallIcon } from "./assets/clock-small.svg";

interface PasskeyViewProps {
  slotId: string;
}

export function PasskeyView({ slotId }: PasskeyViewProps) {
  const contactUsPopup = useContactUsPopup();
  const { stepUpAccess } = useMSAL();
  const [params] = useQueryParams(queries.appointmentPrereq);
  const visitPrerequisites = useVisitPrerequisites({
    origin: params.origin[0],
  });

  const nav = {
    appointment: useNav(paths.appointment, queries.appointmentPrereq),
    prereq: useNav(paths.appointmentPrereq.root),
    passkeyResult: useNav(
      paths.appointmentPrereq.addPasskeyResult,
      queries.appointmentPrereq
    ),
  };

  const onClose = useCallback(() => {
    if (params.origin[0] === "prereq") {
      nav.prereq.go({ slotId });
    } else {
      nav.appointment.go({ slotId });
    }
  }, [nav.appointment, nav.prereq, params.origin, slotId]);

  const onSkip = useCallback(() => {
    const nextStep = visitPrerequisites.getNextStep({ after: "passkey" });
    if (nextStep) {
      nextStep(slotId);
    } else {
      onClose();
    }
  }, [onClose, slotId, visitPrerequisites]);

  const handleAddPasskey = useCallback(() => {
    stepUpAccess(
      "psk",
      nav.passkeyResult.to({ slotId }, { origin: params.origin })
    );
  }, [stepUpAccess, nav.passkeyResult, slotId, params.origin]);

  return (
    <FullScreenPageLayout>
      <BurgerLayout>
        <LogoHeader
          leftElement={<IconButton onClick={onClose} icon={<CrossIcon />} />}
          rightElement={
            <IconButton
              icon={<QuestionIcon display="block" />}
              onClick={contactUsPopup.emit}
            />
          }
        />
        <div className={styles.body}>
          <div className={styles.content}>
            <Typography variant="title-large">
              <Trans.Title />
            </Typography>
            <div className={styles.textContainer}>
              <Typography variant="paragraph">
                <Trans.Paragraph1 />
              </Typography>
              <div className={styles.textWithIcon}>
                <ClockSmallIcon />
                <Typography variant="paragraph-small" color="default">
                  <Trans.SubParagraph1 />
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cta}>
          <div className={styles.action}>
            <ActionButton onClick={handleAddPasskey} variant="suggestion">
              <Trans.AddPasskey />
            </ActionButton>
            <ActionButton onClick={onSkip} variant="secondary">
              <Trans.SetupLater />
            </ActionButton>
          </div>
        </div>
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
