import { ReactComponent as CheckIcon } from "assets/icons/check/check-24x24.svg";
import { ReactComponent as ExIcon } from "assets/icons/ex/ex-24x24.svg";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { Typography } from "render/ui/presentation/Typography";

export function TipsSection() {
  return (
    <div className={styles.TipsSection}>
      <div className={styles.stepsTipsHeader}>
        <Trans.BeforeYourAppointment />
      </div>
      <ul className={styles.stepsTipsList}>
        <li>
          <CheckIcon />
          <Typography variant="paragraph-sub">
            <Trans.CheckList.Eating />
          </Typography>
        </li>
        <li>
          <CheckIcon />
          <Typography variant="paragraph-sub" color="subtle">
            <Trans.CheckList.Exercises />
          </Typography>
        </li>
        <li>
          <ExIcon />
          <Typography variant="paragraph-sub">
            <Trans.CheckList.Caffeine />
          </Typography>
        </li>
        <li>
          <ExIcon />
          <Typography variant="paragraph-sub">
            <Trans.CheckList.Skin />
          </Typography>
        </li>
      </ul>
    </div>
  );
}
