import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { useOrderQuery } from "render/hooks/api/useOrder";
import { paths, queries } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function CheckoutSuccessView() {
  const [params] = useQueryParams(queries.checkout);

  const nav = {
    home: useNav(paths.root),
    booking: useNav(paths.booking),
  };

  const { isSuccess } = useOrderQuery(params.orderId[0], {
    enabled: params.orderId[0] != null,
  });

  return (
    <FullScreenPageLayout>
      <div className={styles.backgroundContainer}>
        <div className={styles.backgroundImage} />
      </div>
      <BurgerLayout>
        <LogoHeader />
        <div className={styles.body}>
          <div className={styles.content}>
            {isSuccess ? (
              <>
                <div className={styles.text}>
                  <Typography variant="title-large">
                    <Trans.AllSet />
                  </Typography>
                  <Typography variant="title-small" color="subtle">
                    <Trans.ConfirmationSentViaEmail />
                  </Typography>
                </div>
                <ActionButton variant="suggestion" onClick={nav.booking.on({})}>
                  <Trans.NextButton />
                </ActionButton>
              </>
            ) : undefined}
          </div>
        </div>
        {null}
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
