import { DateTime } from "luxon";
import { useHasAttendedVisitInSweden } from "render/hooks/api/queries/useVisitsQuery";
import { Separator } from "render/ui/presentation/Separator/Separator";
import { Typography } from "render/ui/presentation/Typography/Typography";
import { MetricsSection } from "render/views/Report/AppointmentDataView/components/MetricsSection";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface ScanDataTabProps {
  latestScanDate: DateTime;
  headerHeight: number;
}

export function ScanDataTab({
  latestScanDate,
  headerHeight,
}: ScanDataTabProps) {
  const { data: hasAttendedVisitInSweden } = useHasAttendedVisitInSweden();

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant="title-large">
          <Trans.Title />
        </Typography>
        <div className={styles.updated}>
          <Typography variant="paragraph-small" color="subtle">
            <Trans.LastUpdated />
          </Typography>
          <Typography variant="paragraph-small">
            &nbsp;
            {latestScanDate.toFormat("dd MMMM yyyy")}
          </Typography>
        </div>
        <Separator margin="24px 0 24px" width="24px" />
      </div>
      <MetricsSection headerHeight={headerHeight} />
      {hasAttendedVisitInSweden != null ? (
        <div className={styles.studies}>
          <Typography variant="paragraph-sub">
            {hasAttendedVisitInSweden === false ? (
              <Trans.StudyInfo />
            ) : (
              <Trans.StudyInfoSweden />
            )}
          </Typography>
        </div>
      ) : undefined}
    </div>
  );
}
