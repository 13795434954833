import { APITypesV2 } from "@cur8/api-client";
import { formatToDesignSystem } from "lib/consents/formatToDesignSystem";
import { isInvalidDocumentError, parseDocument } from "lib/legal/parseDocument";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import React, { useMemo } from "react";
import { useLegalDocumentQuery } from "render/hooks/api/queries/useLegalDocumentQuery";
import { useGiveConsentMutation } from "render/hooks/mutations/consent/useGiveConsentMutation";
import { useOptOutConsentMutation } from "render/hooks/mutations/consent/useOptOutConsentMutation";
import { LegalDocumentLayout } from "render/ui/layout/LegalDocumentLayout/LegalDocumentLayout";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton/ActionButton";
import { CheckInFailView } from "../../../CheckInFailView";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface CheckInConsentDocumentProps {
  consent:
    | {
        consentMetadata: APITypesV2.ConsentMetadata;
        relevantSignature: APITypesV2.ConsentSummary | undefined;
      }
    | undefined;
}

export function CheckInConsentDocument({
  consent,
}: CheckInConsentDocumentProps) {
  const languageCode = useQuestionnaireLanguageCode();

  const documentUri = consent?.consentMetadata?.documents?.find(
    (document) => document.language === languageCode
  )?.uri;

  const { data: legalDocument, isError: legalDocumentIsError } =
    useLegalDocumentQuery(documentUri, {
      select: parseDocument,
    });
  const giveConsentMutation = useGiveConsentMutation();
  const optOutConsentMutation = useOptOutConsentMutation();

  const consentMetadataId = consent?.consentMetadata.consentId;

  const isBusy = useMemo(() => {
    return giveConsentMutation.isPending || optOutConsentMutation.isPending;
  }, [giveConsentMutation.isPending, optOutConsentMutation.isPending]);

  const headElements = legalDocument?.summary?.getElementsByTagName("*");
  const parsedHead = headElements && formatToDesignSystem(headElements);
  const bodyElements = legalDocument?.scrollable?.getElementsByTagName("*");
  const parsedBody = bodyElements && formatToDesignSystem(bodyElements);

  const isOptional =
    consent?.consentMetadata.optional &&
    consent?.consentMetadata.consentType !== APITypesV2.ConsentType.Study;

  if (legalDocumentIsError) {
    if (isInvalidDocumentError(legalDocumentIsError)) {
      return (
        <CheckInFailView
          Header={<Trans.InvalidDocument.Header />}
          Text={<Trans.InvalidDocument.Description />}
        />
      );
    }
    return (
      <CheckInFailView
        Header={<Trans.DocumentLoadFail.Header />}
        Text={<Trans.DocumentLoadFail.Description />}
      />
    );
  }

  return (
    <LegalDocumentLayout
      Description={
        <div className={styles.text}>
          <Typography variant="title-large">{legalDocument?.title}</Typography>
          <Typography variant="title-small">
            {legalDocument?.description}
          </Typography>
        </div>
      }
      Content={
        <div className={styles.text}>
          {parsedHead?.map((element, index) => (
            <React.Fragment key={index}>{element}</React.Fragment>
          ))}
          {parsedBody?.map((element, index) => (
            <React.Fragment key={index}>{element}</React.Fragment>
          ))}
        </div>
      }
      Actions={
        <>
          <ActionButton
            disabled={isBusy}
            onClick={() =>
              giveConsentMutation.mutate({
                documentUri,
                consentMetadataId,
              })
            }
          >
            <Trans.CTA.Agree />
          </ActionButton>
          {isOptional ? (
            <ActionButton
              disabled={isBusy}
              variant="secondary"
              onClick={() =>
                optOutConsentMutation.mutate({
                  documentUri,
                  consentMetadataId,
                })
              }
            >
              <Trans.CTA.Reject />
            </ActionButton>
          ) : undefined}
        </>
      }
    />
  );
}
