import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import { HealthConditions } from "lib/questionnaire/question-flows/onboarding/questions/healthConditions";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { MultiChoiceInputWithDescriptions } from "render/ui/presentation/Questionnaire/components/MultiChoiceInputWithDescriptions";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function HealthConditionsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(HealthConditions);
  const label = TransQuestions.HealthConditions.Question();
  const { englishLabel } = TransQuestions.HealthConditions.Question;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={HealthConditions}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(HealthConditions);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.HealthConditionsExplanation />,
      }}
    >
      <MultiChoiceInputWithDescriptions
        question={HealthConditions}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(HealthConditions, {
            answer,
            questionAnswer: {
              questionId: "healthConditions",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "textarray",
                value: HealthConditions.getValue(answer),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[]) {
  steps.push(HealthConditionsStep);
}
