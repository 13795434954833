import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useNav } from "@pomle/react-router-paths";
import { ViewStack } from "@pomle/react-viewstack";
import { Fade } from "@pomle/react-viewstack-transitions";
import { ReactComponent as DownloadIcon } from "assets/download.svg";
import { ReactComponent as LogoIcon } from "assets/logo.svg";
import { ReactComponent as ShareIcon } from "assets/share.svg";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { usePostScanIntro } from "render/context/PostScanIntroContext";
import { useScanCompleteAssetDetailsQuery } from "render/hooks/api/queries/useScanCompleteAssetDetailsQuery";
import { useScanCompleteAssetQuery } from "render/hooks/api/queries/useScanCompleteAssetQuery";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { LogoView } from "render/views/LogoView";
import { Timings } from "./animations";
import { IntroText } from "./components/IntroText";
import { ScanCompleteAsset } from "./components/ScanCompleteAsset";
import styles from "./styles.module.sass";
import { postScanIntroEvent } from "./tracking";
import { Trans } from "./trans";

export function PostScanIntroView() {
  const { latestVisit, markAsSeen, setIsOpen } = usePostScanIntro();

  const appInsights = useAppInsights();
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent(postScanIntroEvent.pageView());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shareableAssetDetailsQuery = useScanCompleteAssetDetailsQuery(
    latestVisit?.visitId,
    {
      enabled: !!latestVisit?.visitId,
    }
  );
  const shareableAssetQuery = useScanCompleteAssetQuery(latestVisit?.visitId, {
    enabled: !!latestVisit?.visitId,
  });

  useEffect(() => {
    if (shareableAssetDetailsQuery.isError || shareableAssetQuery.isError) {
      setIsOpen(false);
    }
  }, [
    shareableAssetDetailsQuery.isError,
    shareableAssetQuery.isError,
    setIsOpen,
  ]);

  const appointmentSummary = useNav(paths.appointmentSummary);

  const nativeShareSupported = !!navigator.share;

  const handleNativeShare = useCallback(async () => {
    if (!shareableAssetQuery.data) {
      return;
    }

    try {
      await navigator.share({
        files: [
          new File(
            [shareableAssetQuery.data],
            "Neko Health - Scan Complete.png",
            {
              type: "image/png",
            }
          ),
        ],
      });
    } catch (error) {
      if (error instanceof Error) {
        appInsights.trackException({
          exception: error,
          properties: { description: "Error sharing the image" },
          severityLevel: SeverityLevel.Error,
        });
      }
    }
  }, [shareableAssetQuery.data, appInsights]);

  const handleFallbackShare = useCallback(async () => {
    if (!shareableAssetQuery.data) {
      return;
    }

    const objectUrl = URL.createObjectURL(shareableAssetQuery.data);

    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = "Neko Health - Scan Booked.png";
    a.click();
  }, [shareableAssetQuery.data]);

  const handleShare = useCallback(async () => {
    if (!shareableAssetQuery.data) {
      return;
    }

    trackEvent(
      postScanIntroEvent.shareClick(
        nativeShareSupported ? "nativeShare" : "download"
      )
    );

    if (nativeShareSupported) {
      await handleNativeShare();
    } else {
      await handleFallbackShare();
    }
  }, [
    shareableAssetQuery.data,
    nativeShareSupported,
    handleNativeShare,
    handleFallbackShare,
    trackEvent,
  ]);

  const handleContinue = useCallback(() => {
    trackEvent(postScanIntroEvent.continueClick());
    appointmentSummary.go({});
    setIsOpen(false);
  }, [trackEvent, setIsOpen, appointmentSummary]);

  const [isAssetsReady, setIsAssetsReady] = useState(false);

  const handleAssetReady = () => {
    setIsAssetsReady(true);
  };

  useEffect(() => {
    if (!isAssetsReady) {
      return;
    }

    markAsSeen();
  }, [isAssetsReady, markAsSeen]);

  const buttonsDisabled = shareableAssetQuery.isFetching;

  const assetParameters = useMemo(() => {
    if (!shareableAssetDetailsQuery.data) {
      return undefined;
    }

    return {
      name: shareableAssetDetailsQuery.data.parameters.name,
      date: shareableAssetDetailsQuery.data.parameters.date,
      scan: shareableAssetDetailsQuery.data.parameters.scan,
      siteName: shareableAssetDetailsQuery.data.parameters.siteName,
    };
  }, [shareableAssetDetailsQuery.data]);

  const [introAnimationEnded, setIntroAnimationEnded] = useState(false);
  const handleIntroAnimationEnded = () => setIntroAnimationEnded(true);

  const postIntroAnimationPlayState = useMemo(
    () => (introAnimationEnded ? "running" : "paused"),
    [introAnimationEnded]
  );

  // hack(ford): The logo should be placed at the top of the stack, but position is determined by the card which is deep
  //             Maybe there is a better way to do this?
  const [logoRect, setLogoRect] = useState<DOMRect>();
  const handleLogoPositionChanged = useCallback((rect: DOMRect) => {
    setLogoRect(rect);
  }, []);

  return (
    <FullScreenPageLayout>
      <ViewStack>
        {!isAssetsReady && <LogoView />}
        <Fade active={isAssetsReady}>
          <div className={styles.stacked}>
            <div className={styles.content}>
              <div className={styles.shareContainer}>
                <div
                  className={styles.shareContent}
                  style={
                    {
                      "--play-state": postIntroAnimationPlayState,
                      "--duration": `${Timings.cardTransitionDurationSec}s`,
                    } as CSSProperties
                  }
                >
                  {assetParameters && (
                    <ScanCompleteAsset
                      name={assetParameters.name}
                      date={assetParameters.date}
                      scan={assetParameters.scan}
                      siteName={assetParameters.siteName}
                      playAnimation={introAnimationEnded}
                      onReady={handleAssetReady}
                      onLogoPositionChanged={handleLogoPositionChanged}
                    />
                  )}

                  <div className={styles.buttons}>
                    <div
                      className={styles.shareButton}
                      style={
                        {
                          "--play-state": postIntroAnimationPlayState,
                          "--delay": `${Timings.buttonShareDelaySec}s`,
                          "--duration": `${Timings.buttonShareDurationSec}s`,
                        } as CSSProperties
                      }
                    >
                      <ActionButton
                        hideIcon
                        onClick={handleShare}
                        disabled={buttonsDisabled}
                      >
                        <Typography variant="cta">
                          <Trans.Share />
                        </Typography>
                        {nativeShareSupported ? (
                          <ShareIcon />
                        ) : (
                          <DownloadIcon />
                        )}
                      </ActionButton>
                    </div>

                    <div
                      className={styles.continueButton}
                      style={
                        {
                          "--play-state": postIntroAnimationPlayState,
                          "--delay": `${Timings.buttonContinueDelaySec}s`,
                          "--duration": `${Timings.buttonContinueDurationSec}s`,
                        } as CSSProperties
                      }
                    >
                      <ActionButton
                        hideIcon
                        variant="suggestion"
                        onClick={handleContinue}
                        disabled={buttonsDisabled}
                      >
                        <div className={styles.fullWidth}>
                          <Typography variant="cta">
                            <Trans.ViewReport />
                          </Typography>
                        </div>
                      </ActionButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={styles.blur}
              style={
                {
                  "--play-state": postIntroAnimationPlayState,
                  "--duration": `${Timings.cardTransitionDurationSec}s`,
                } as CSSProperties
              }
            >
              <IntroText
                text={Trans.IntroText()}
                playAnimation={isAssetsReady}
                onAnimationEnded={handleIntroAnimationEnded}
              />
            </div>

            <div
              className={styles.logo}
              style={
                {
                  "--top": `${logoRect?.top}px`,
                  "--height": `${logoRect?.height}px`,
                  "--duration": `${Timings.cardLogoDurationSec}s`,
                  "--play-state": postIntroAnimationPlayState,
                } as CSSProperties
              }
            >
              <LogoIcon />
            </div>
          </div>
        </Fade>
      </ViewStack>
    </FullScreenPageLayout>
  );
}
