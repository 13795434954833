import { RouterContext } from "@pomle/react-router-paths";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { APIContext } from "render/context/APIContext";
import { AppInsightsContext } from "render/context/AppInsightsContext";
import { ConfigContext } from "render/context/ConfigContext";
import { ErrorBoundaryContext } from "render/context/ErrorBoundaryContext";
import {
  AuthSessionLanguageSetter,
  InternationalizationContext,
} from "render/context/InternationalizationContext";
import { Authenticated, MSALContext } from "render/context/MSALContext";
import { PopupContext } from "render/context/PopupContext";
import { PostScanIntroContext } from "render/context/PostScanIntroContext";
import { QueryContext } from "render/context/QueryContext";
import { SnackbarContext } from "render/context/SnackbarContext";
import { TrackingContext } from "render/context/TrackingContext";
import { Router } from "render/routes/Router";
import { LoginErrorView } from "render/views/LoginError/LoginErrorView";
import "./App.sass";
import { ApplicationLayout } from "./components/ApplicationLayout";

export function App() {
  return (
    <ConfigContext>
      <AppInsightsContext>
        <ErrorBoundaryContext>
          <RouterContext history={window.history}>
            <TrackingContext>
              <MSALContext>
                <APIContext>
                  <QueryContext>
                    <ReactQueryDevtools />
                    <InternationalizationContext>
                      <Authenticated ErrorView={LoginErrorView}>
                        <AuthSessionLanguageSetter>
                          <SnackbarContext>
                            <PopupContext>
                              <ApplicationLayout>
                                <PostScanIntroContext>
                                  <Router />
                                </PostScanIntroContext>
                              </ApplicationLayout>
                            </PopupContext>
                          </SnackbarContext>
                        </AuthSessionLanguageSetter>
                      </Authenticated>
                    </InternationalizationContext>
                  </QueryContext>
                </APIContext>
              </MSALContext>
            </TrackingContext>
          </RouterContext>
        </ErrorBoundaryContext>
      </AppInsightsContext>
    </ConfigContext>
  );
}
