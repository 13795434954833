import { APITypesV1 } from "@cur8/api-client";
import { Study } from "render/views/ProfileView/components/DataAndPrivacySection/hooks/useConsentsMap";

interface IsOnboardingCompleteProps {
  onboardingData: boolean | undefined;
  patientConsents: APITypesV1.ConsentSummary[];
  requiredStudies: { [x: string]: Study };
}

export function isAtriumOnboardingComplete({
  patientConsents,
  requiredStudies,
  onboardingData,
}: IsOnboardingCompleteProps): boolean {
  if (!onboardingData) {
    return false;
  }

  return patientConsents.some((consent) => {
    return Object.values(requiredStudies).some((study) =>
      consent.documentUrl.includes(study.name)
    );
  });
}
