import { Eosinophils } from "@cur8/health-risks-calc";
import { PathLink } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useEosinophilsMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricEosinophils() {
  const { data, isLoading: isDataLoading } = useEosinophilsMetric();

  if (isDataLoading) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!data) {
    return null;
  }

  const { cur, prev } = getMetricHistory(data);
  const riskRanges = Eosinophils.rangesFor();
  const metricRating = cur && riskRanges.findRisk(cur.unit);
  const value = cur?.unit["x10⁹/L"];
  const prevValue = prev?.unit["x10⁹/L"];

  return (
    <PathLink to={paths.eosinophilMetric.url({})}>
      <Metric
        label={<Trans.MetricName />}
        metricRating={metricRating}
        value={value}
        subLabel={<Trans.MetricLabel />}
        previousValue={prevValue}
        showPreviousInsteadOfChange={true}
      />
    </PathLink>
  );
}
