import { ABI as ABICalc } from "@cur8/formulas";
import { ABI } from "@cur8/health-risks-calc";
import { PathLink } from "@pomle/react-router-paths";
import { Metric } from "lib/api/metrics";
import { withDecimals } from "lib/math";
import { paths } from "render/routes/paths";
import { getHighestSide } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getHighestSide";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import {
  useAnklePressureMetric,
  useBrachialPressureMetric,
} from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric as MetricLayout, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

const getHighest = (left?: number, right?: number) => {
  if (left === undefined) return right;
  if (right === undefined) return left;
  return Math.max(left, right);
};

function getABIScoreForSide(
  side: "left" | "right",
  leftAnklePressure?: Metric<"cardio.ankle_pressure.left">,
  rightAnklePressure?: Metric<"cardio.ankle_pressure.right">,
  highestPrevBrachialPressure?: number
) {
  if (!highestPrevBrachialPressure) {
    return undefined;
  }

  if (side === "left") {
    if (leftAnklePressure) {
      return ABICalc.calculate({
        highestAnklePressure: {
          mmHg: leftAnklePressure?.unit.mmHg,
        },
        highestSystolicBrachialPressure: {
          systolic: {
            mmHg: highestPrevBrachialPressure,
          },
        },
      }).abi;
    }

    return undefined;
  }

  if (side === "right") {
    if (rightAnklePressure) {
      return ABICalc.calculate({
        highestAnklePressure: {
          mmHg: rightAnklePressure?.unit.mmHg,
        },
        highestSystolicBrachialPressure: {
          systolic: {
            mmHg: highestPrevBrachialPressure,
          },
        },
      }).abi;
    }

    return undefined;
  }

  return undefined;
}

function getABIRiskForSide(
  side: "left" | "right",
  leftAnklePressure?: Metric<"cardio.ankle_pressure.left">,
  rightAnklePressure?: Metric<"cardio.ankle_pressure.right">,
  highestBrachialPressure?: number
) {
  if (!highestBrachialPressure) {
    return undefined;
  }

  if (side === "left") {
    if (leftAnklePressure) {
      return calculateABIRisk({
        highestBrachialPressure,
        anklePressure: leftAnklePressure?.unit.mmHg,
      });
    }

    return undefined;
  }

  if (side === "right") {
    if (rightAnklePressure) {
      return calculateABIRisk({
        highestBrachialPressure,
        anklePressure: rightAnklePressure?.unit.mmHg,
      });
    }

    return undefined;
  }
}

interface CalculateABIRiskProps {
  highestBrachialPressure: number;
  anklePressure: number;
}

function calculateABIRisk({
  highestBrachialPressure,
  anklePressure,
}: CalculateABIRiskProps) {
  const score = ABICalc.calculate({
    highestAnklePressure: {
      mmHg: anklePressure,
    },
    highestSystolicBrachialPressure: {
      systolic: {
        mmHg: highestBrachialPressure,
      },
    },
  }).abi;

  const riskRanges = ABI.rangesFor();
  const risk = riskRanges.findRisk({ ratio: score });
  return risk;
}

export function MetricABI() {
  const { data: brachialPressure, isLoading: isBrachialPressureLoading } =
    useBrachialPressureMetric();
  const { data: anklePressure, isLoading: isAnklePressureLoading } =
    useAnklePressureMetric();

  if (isBrachialPressureLoading || isAnklePressureLoading) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!brachialPressure || !anklePressure) {
    return null;
  }

  const { cur: latestLeftAnklePressure, prev: prevLeftAnklePressure } =
    getMetricHistory(anklePressure.left);
  const { cur: latestRightAnklePressure, prev: prevRightAnklePressure } =
    getMetricHistory(anklePressure.right);

  const highestCurrentSide = getHighestSide({
    left: latestLeftAnklePressure?.unit.mmHg,
    right: latestRightAnklePressure?.unit.mmHg,
  });

  const { cur: latestLeftBrachialPressure, prev: prevLeftBrachialPressure } =
    getMetricHistory(brachialPressure.left);
  const { cur: curRightBrachialPressure, prev: prevRightBrachialPressure } =
    getMetricHistory(brachialPressure.right);

  const highestBrachialPressure = getHighest(
    latestLeftBrachialPressure?.unit.systolic.mmHg,
    curRightBrachialPressure?.unit.systolic.mmHg
  );

  const highestPrevBrachialPressure = getHighest(
    prevLeftBrachialPressure?.unit.systolic.mmHg,
    prevRightBrachialPressure?.unit.systolic.mmHg
  );

  const score = getABIScoreForSide(
    highestCurrentSide,
    latestLeftAnklePressure,
    latestRightAnklePressure,
    highestBrachialPressure
  );
  const prevScore = getABIScoreForSide(
    highestCurrentSide,
    prevLeftAnklePressure,
    prevRightAnklePressure,
    highestPrevBrachialPressure
  );

  const metricRating = getABIRiskForSide(
    highestCurrentSide,
    latestLeftAnklePressure,
    latestRightAnklePressure,
    highestBrachialPressure
  );

  return (
    <PathLink to={paths.abiMetric.url({})}>
      <MetricLayout
        label={<Trans.MetricName />}
        value={score ? withDecimals(score, 2) : undefined}
        metricRating={metricRating}
        subLabel={undefined}
        previousValue={prevScore ? withDecimals(prevScore, 2) : undefined}
      />
    </PathLink>
  );
}
