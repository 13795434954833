import { Slot } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-28x28.svg";
import { ReactComponent as ExIcon } from "assets/icons/ex/ex-28x28.svg";
import { useMemo } from "react";
import { usePopup } from "render/context/PopupContext";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths } from "render/routes/paths";
import { LogoHeader } from "render/ui/layout/LogoHeader/LogoHeader";
import { Accordion } from "render/ui/presentation/Accordion";
import { Typography } from "render/ui/presentation/Typography";
import { IconButton } from "render/ui/trigger/IconButton";
import { TipsSection } from "render/views/appointment/AppointmentView/components/AppointmentInfoSection/components/TipSection";
import { SharedTrans } from "render/views/trans";
import DoctorImage from "./assets/doctor.webp";
import { CompleteProfileButton } from "./components/CompleteProfileButton/CompleteProfileButton";
import { StepsSection } from "./components/StepsSection";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface AppointmentInfoSectionProps {
  slot: Slot;
}

export function AppointmentInfoSection({ slot }: AppointmentInfoSectionProps) {
  const popUpDialogContext = usePopup();
  const contactUsPopup = useContactUsPopup();
  const nav = {
    home: useNav(paths.root),
  };

  const date = useMemo(
    () => slot.startTime.setZone(slot.room?.site?.timeZoneId),
    [slot.startTime, slot.room?.site?.timeZoneId]
  );
  const startTime = useMemo(
    () => slot.startTime.setZone(slot.room?.site?.timeZoneId),
    [slot.room?.site?.timeZoneId, slot.startTime]
  );
  const endTime = useMemo(
    () => slot.endTime.setZone(slot.room?.site?.timeZoneId),
    [slot.endTime, slot.room?.site?.timeZoneId]
  );
  const duration = useMemo(
    () => endTime.diff(startTime, "minute").minutes,
    [startTime, endTime]
  );

  const address = slot.room?.site?.address;
  const map = encodeURIComponent(
    `${address?.city}, ${address?.country}, ${address?.street}, ${address?.postalCode}`
  );

  return (
    <div className={styles.container}>
      <div
        data-blured={popUpDialogContext.isActive}
        className={styles.fixedHeader}
      >
        <LogoHeader
          leftElement={
            <IconButton onClick={() => nav.home.go({})} icon={<ExIcon />} />
          }
          rightElement={
            <IconButton
              ariaLabel={SharedTrans.ContactUs()}
              icon={<QuestionIcon display="block" />}
              onClick={() => {
                contactUsPopup.emit();
              }}
            />
          }
        />
      </div>
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.sectionHeader}>
            <div className={styles.badge}>
              <Trans.Status />
            </div>
            <div className={styles.header}>
              <Typography variant="title-large">
                <Trans.Title />
              </Typography>
            </div>
          </div>
          <div data-hj-suppress className={styles.sectionTime}>
            <div className={styles.date}>
              <Typography variant="number-medium">
                {date.toFormat("dd")}
              </Typography>
              <div className={styles.month}>
                <span className={styles.bolded}>{date.toFormat("MMMM")}</span>,{" "}
                {date.toFormat("EEEE")}
              </div>
            </div>
            <div className={styles.hour}>
              <Typography variant="number-medium">
                {startTime.toFormat("HH:mm")}
              </Typography>
              <div className={styles.endTime}>
                <Trans.EndTime
                  duration={duration}
                  endTime={endTime.toFormat("HH:mm")}
                />
              </div>
            </div>
          </div>
          <div className={styles.separator} />
          <div className={styles.sectionLocation}>
            <div className={styles.findUs}>
              <Trans.FindUs.Title />
            </div>
            <a
              className={styles.address}
              target="_blank"
              rel="noreferrer"
              href={`http://google.com/maps/?q=${map}`}
            >
              {address?.street}
            </a>
            <div className={styles.arival}>
              <Trans.FindUs.Description />
            </div>
          </div>
          <div className={styles.sectionDoctorImage}>
            <img src={DoctorImage} alt="doctor" width="100%" height="100%" />
          </div>

          <div
            className={styles.blurBackground}
            data-blured={popUpDialogContext.isActive}
          >
            <div className={styles.stepsSectionMobile}>
              <div className={styles.stepsContainer}>
                <StepsSection slot={slot} />
              </div>
              <div className={styles.tipsContainer}>
                <TipsSection />
              </div>
            </div>
            <div className={styles.sectionFAQ}>
              <Typography variant="title-medium">
                <Trans.FAQ.Title />
              </Typography>
              <ol className={styles.sectionFAQList}>
                <li className={styles.sectionFAQListItem}>
                  <div className={styles.sectionFAQListCounter}>01</div>
                  <Accordion
                    header={<Trans.FAQ.Skin.Header />}
                    text={
                      <div className={styles.explanation}>
                        <Trans.FAQ.Skin.Body />
                      </div>
                    }
                  />
                </li>
                <li className={styles.sectionFAQListItem}>
                  <div className={styles.sectionFAQListCounter}>02</div>
                  <Accordion
                    header={<Trans.FAQ.Heart.Header />}
                    text={
                      <div className={styles.explanation}>
                        <Trans.FAQ.Heart.Body />
                      </div>
                    }
                  />
                </li>
                <li className={styles.sectionFAQListItem}>
                  <div className={styles.sectionFAQListCounter}>03</div>
                  <Accordion
                    header={<Trans.FAQ.Blood.Header />}
                    text={
                      <div className={styles.explanation}>
                        <Trans.FAQ.Blood.Body />
                      </div>
                    }
                  />
                </li>
                <li className={styles.sectionFAQListItem}>
                  <div className={styles.sectionFAQListCounter}>04</div>
                  <Accordion
                    header={<Trans.FAQ.Consultation.Header />}
                    text={
                      <div className={styles.explanation}>
                        <Trans.FAQ.Consultation.Body />
                      </div>
                    }
                  />
                </li>
              </ol>
            </div>
          </div>
          <div className={styles.mobileProfileButton}>
            <CompleteProfileButton slot={slot} />
          </div>
        </div>
        <div className={styles.stepsSectionDesktop}>
          <div className={styles.stepsContent}>
            <div className={styles.stepsContainer}>
              <StepsSection slot={slot} />
            </div>
            <div className={styles.tipsContainer}>
              <TipsSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
